// Initialize FingerprintJS
window["fpPromise"] = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = resolve;
    script.onerror = reject;
    script.async = true;
    script.src = 'https://cdn.jsdelivr.net/npm/'
        + '@fingerprintjs/fingerprintjs@3/dist/fp.min.js';
    document.head.appendChild(script);
}).then(() => FingerprintJS.load());

$(function () {
// Only call backend when "send-bfp" element is specified
    if (document.getElementById("send-bfp")) {
        fpPromise
            .then(fp => fp.get())
            .then(result => {
                fetch('//' + apiHost + '/v1/bfp/' + result.visitorId,
                    {
                        credentials: 'include',
                        mode: 'no-cors' // 'cors' by default
                    }
                )
            });
    }
});
