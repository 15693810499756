window["auto_save_intvl"] = 60000;
window["DraftAutoSave"] = function () {
    $('#saveDraftBtn').trigger('click');
    window.setTimeout('DraftAutoSave()', auto_save_intvl);
}

window["show_msg_history"] = function (t) {
    const tNode = $(t);
    const boxNode = $(tNode.data('target'));
    $.ajax({
        url: tNode.data('url'),
    }).done(function (data) {
        boxNode.append(data);
        boxNode.show();
        if (typeof tNode.data('scroll') != 'undefined') {
            window.setTimeout(function () {
                $.scrollTo($(tNode.data('scroll')), 800);
            }, 100);
        }
        tNode.remove();
    }).fail(function (jqXHR, textStatus, errorThrown) {
    });
}

$(function () {
    if (!(new RegExp('mbox.php|mcompose.php|drafts.php')).test(window.location.href)) {
        return;
    }
    $('#msg').focus(function () {
        $(this).removeClass('MsgTAClp').addClass('MsgTAExp');
    });
    $('.MsgCB').show().removeAttr('checked').click(function () {
        $('#mr_' + $(this).val()).toggleClass('mrSelected');
    });

    // Delete messages via ajax
    $(".delMsgLink").addClass('msgRedX').click(function (e) {
            e.preventDefault();
            $(this).removeClass('msgRedX').addClass('ajx_circ_16');
            ajxDelete(this, $(this).parents(".msgRow"));
        }
    );

    $(".msgSpamLink").addClass('msgSpamIcon');

    // Save draft to drafts.php, update message form's draft_id field with
    // the draft id and add a "saved draft at..." field
    $('#saveDraftBtn').show().click(function (e) {
        e.preventDefault();
        var draft_btn = $(this);
        // Grab some data from message
        var msg_form = $(this).parents('form');
        var msg = msg_form.find("textarea[name='message']").val();
        var sub = msg_form.find("input[name='subject']").val();
        if (msg != '') {
            // Begin the action...
            $(draft_btn).attr("disabled", true).val("Saving...");
            $.ajax({
                type: 'POST',
                url: '/drafts.php',
                data: {
                    todo: 'save',
                    to: msg_form.find("input[name='to']").val(),
                    message: msg,
                    subject: sub,
                    draft_id: msg_form.find("input[name='draft_id']").val(),
                    in_reply_to_id: msg_form.find("input[name='reply']").val()
                },
                dataType: "json",
                success: function (data) {
                    if (data.draft_id !== undefined) {
                        msg_form.find("input[name='draft_id']").val(data.draft_id);
                        $(draft_btn).attr("disabled", false).val("Save Draft");
                        // TODO: get the whole string from drafts.php for multilanguage support
                        $('#saveDraftStat').html("Draft saved at " + data.saved);
                    } else {
                        $('#saveDraftStat').html(data.error);
                    }
                }
            });
        }
    });
    // Auto-save the draft every x seconds
    window.setTimeout('DraftAutoSave()', auto_save_intvl);

    // Reply link focuses on reply box
    $('#replLink').addClass('clickable').click(function () {
        $('#msg').focus();
    });
});
