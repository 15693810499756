//document.domain = "interpals.net"
//document.domain = /\w+\.[a-z]+$|^\w+$|^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/i.exec(location.hostname);

// Flash a message in the browser title bar at 1 second intervals
window["flashTitle"] = function (newTitle) {
    window.stopFlashTitle();

    // Save original title
    window.original_title = document.title;

    // Set new interval
    window.title_interval_id = setInterval(function () {
        document.title = (document.title == window.original_title) ? newTitle : window.original_title;
    }, 1000);
}

window.stopFlashTitle = function () {
    if (window.title_interval_id) {
        // Clear previous interval if any
        clearInterval(window.title_interval_id);
        // Restore title
        document.title = window.original_title;
        window.title_flash_type = null;
    }
}

window.showNotifications = function (msgs) {
    for (let i = 0; i < msgs.length; i++) {
        // Skip msg notification if user browsing this msg thread
        if ((new RegExp('pm.php')).test(window.location.href) &&
            typeof msgs[i]['thread_id'] != 'undefined' &&
            window.get_active_thread_id() == msgs[i]['thread_id']
        ) {
            continue;
        }

        if (document.readyState !== 'complete') {
            return;
        }

        const notifCont = $("#notifCont");
        if (typeof notifCont.notify === 'function') {
            notifCont.notify("create", {
                speed: 500,
                url: msgs[i].url,
                username: msgs[i].username,
                title: msgs[i].title,
                text: msgs[i].text,
                img: '<img src="' + msgs[i].img_url + '" ' + msgs[i].img_dims + '>'
            });
        }
    }
}

// Initialize notification container
$(document).ready(function () {
    const notifCont = $("#notifCont");
    if (typeof notifCont.notify !== 'function') {
        console.error('Notify plugin not loaded', notifCont, notifCont.notify);
    } else {
        notifCont.notify({ speed: 500 });
    }
});

// Online status updater: calls online_update.php, which updates online/session
// data while returning pm, wallpost, and friend new counts (to be embedded in page)
window["updateOnlineStatus"] = function () {

    // Skip for pm.php, there we will use update_pm_info ajax call
    if ((new RegExp('pm.php')).test(window.location.href)) return;

    // Check cache
    var cached = false,
        interval = typeof window.online_update_interval == "number" ? window.online_update_interval : 7000,
        time = (new Date().getTime());

    // If storage avalible and cache not stale
    if (is_local_storage_supported() && localStorage.ip_online_update_time
        // 200 milisecond delta - because this triggered for some reason early then needed
        && (parseInt(localStorage.ip_online_update_time) + interval) > (time + 200)) {
        cached = localStorage.ip_online_update;
    }

    if (cached) {
        const json = JSON.parse(cached);
        update_success(json);
        update_complete();
    } else {
        $.ajax({
            url: online_update_domain + '/async/online_update.php?callback=?',
            crossDomain: true,
            dataType: 'jsonp',
            data: { ts: window.cur_ts },
            success: update_success,
            complete: update_complete
        });
    }
}

window["local_storage_supported"] = null;

window["is_local_storage_supported"] = function () {
    // Return from cache
    if (window["local_storage_supported"] != null) return window["local_storage_supported"];
    // Else check and cache result
    if ('localStorage' in window && window['localStorage'] !== null) {
        try {
            localStorage.setItem("in_test", "in_test");
            localStorage.getItem("lastname");
        } catch (error) {
            window["local_storage_supported"] = false;
            return false;
        }
        window["local_storage_supported"] = true;
        return true;
    }
    window["local_storage_supported"] = false;
    return false;
}

window["update_success"] = function (json, b) {
    window["newTitle"] = setCount(json, 'pmNewCnt', 'New Message');
    window["newTitle"] = setCount(json, 'wpNewCnt', 'New Wall Post');
    window["newTitle"] = setCount(json, 'frNewCnt', 'New Friend Request');
    window["newTitle"] = setCount(json, 'phNewCnt', 'New Photo Comment');
    window["newTitle"] = setCount(json, 'ncNewCnt', 'New Note Comment');

    if (window.enable_notifs) {
        window.showNotifications(json.msgs);
    }
    window.cur_ts = json.ts;

    // If it was real update - cache it
    if (typeof (b) != "undefined" && is_local_storage_supported()) {
        // Use html storage if avaliable to cache online update for interval
        localStorage.ip_online_update_time = (new Date().getTime());
        localStorage.ip_online_update = JSON.stringify(json);
    }
}

window["update_complete"] = function () {
    // Run again = function in "interval" after this run finished.
    var interval = typeof window.online_update_interval == "number" ? window.online_update_interval : 7000;
    window.setTimeout('updateOnlineStatus()', interval);
}

window["setCount"] = function (json, id, msg) {
    if ($('#' + id).text() < json[id]) {
        // Generate alert if new total is greater than old total.
        flashTitle('****  ' + msg + '  ****');
        window.title_flash_type = id;
    } else if (json[id] == '' && window.title_flash_type == id) {
        window.stopFlashTitle();
    }
    $('#' + id).text(json[id]);
}

// Next refresh: designed to automatically load next set of random penpals or
// language pals after a specified amount of time. However, if a user manually
// clicks the next arrow, automatic refreshes are disabled
window["nRfsh"] = function (elems, intvl) {
    elems.each(function () {
        if ($(this).data("norefresh") !== 1) {
            $(this).trigger('click');
            $(this).data("norefresh", 0);
        }
    });
    window.setTimeout(function () {
        nRfsh(elems, intvl);
    }, intvl);
}

window["ajxDelete"] = function (obj, tohide) {
    url = $(obj).attr('href');
    $.ajax({
        type: "GET",
        url: url,
        data: "&ajax=1",
        success: function (d) {
            if (d == '1') {
                $(tohide).hide();
            } else {
                window.location = url;
            }
        },
        error: function () {
            window.location = url;
        }
    });
}

window["htm5_history_supported"] = typeof history != 'undefined'
    && typeof history.pushState != 'undefined';


window["genCityAcOptions"] = function (cityNameField) {
    return {
        listLocation: "items",
        matchResponseProperty: "inputPhrase",
        getValue: "name",
        requestDelay: 100,
        url: function (phrase) {
            return "/app/async/geoAc?query=" + phrase;
        },
        template: {
            type: "iconLeft",
            fields: {
                iconSrc: "icon"
            }
        },
        preparePostData: function () {
            cityNameField.css({ 'background': 'url("/images/prog/circ-16.gif") no-repeat right' });
        },
        list: {
            onSelectItemEvent: function () {
                var index = cityNameField.getSelectedItemData().id;
                cityNameField.parent().siblings('.cityVal').val(index).trigger("change");
            },
            onShowListEvent: function () {
                cityNameField.css({ 'background': '' });
            },
            onHideListEvent: function () {
                cityNameField.css({ 'background': '' });
            }
        }
    };
}

// DOM-dependent jQuery stuff:
$(function () {
    $('#ifLangSelect').change(function () {
        var lang = $("#ifLangSelect option:selected").val()
        console.log(lang)
        $.ajax({
            url: '/app/settings?todo=set_lang&lang=' + lang
        }).done(function () {
            window.location.reload();
        })
    })
    // Detection of html5 history api
    // Closing of "windows": lets users hide boxes that have the .closeable
    // class (by clicking on the little 'x'); We then notify a script
    // (in url=...) so that the setting can be saved
    $(".closer").addClass("clickable").click(function () {
        var pdiv = $(this).parents(".closeable");
        if ($(pdiv).attr('url') !== undefined) {
            $(pdiv).addClass("ajx_load")
                .text("Closing...");
            $.get($(pdiv).attr('url'), function () {
                $(pdiv).hide();
            });
        } else {
            $(pdiv).hide();
        }
    });

    // Load content into element & activate both the "Loading..." message and
    // .ajx_load class (spinwheel); Note that the icon may not display if not
    // preloaded (currently unimplemented)
    window["loadInto"] = function (e, tgt, url) {
        tgt.text('Loading...')
            .addClass('ajx_load')
            .load(url, function () {
                $(this).removeClass('ajx_load').slideDown("fast");
            });
        e.preventDefault();
        return tgt;
    }

    // Toggling content into divs: This is used primarily for the bookmark,
    // friends, and quick search drop-down boxes under "My Interpals" (right bar)
    $(".loadInto").addClass("clickable");
    $(document).on('click', '.loadInto', function (e) {
        var tgt = $('#' + $(this).attr('tgt'));
        if (tgt.is(":visible") && !tgt.is(":empty")) {
            tgt.slideUp("fast");
            $(this).children(".arrow").toggleClass("down");
        } else {
            loadInto(e, tgt, $(this).attr("href"));
            $(this).children(".arrow").toggleClass("down");
        }
    });

    // Simple ajax loading of content into element: tgt = id, href = content url
    $('.loadSimp').click(function (e) {
        loadInto(e, $('#' + $(this).attr('tgt')), $(this).attr('href'));
    });
    $('.loadNext').each(function () {
        $(this).click(function (e) {
            $(this).data("norefresh", 1);
            e.preventDefault();
            $('#' + $(this).attr('tgt')).load($(this).attr('href'));
        });
    });

    // Confirmations: pop-up confirm box with the contents of conf=...
    $(".confirm").click(function (e) {
        const cf = $(this).attr("confFunc")
        if (cf) {
            e.preventDefault();
            if (confirm($(this).attr("conf"))) {
                if (typeof window[cf] === "function") {
                    const func = window[cf]
                    func(this)
                } else if (cf) {
                    const ff = new Function('parentNode', cf);
                    ff(this.parentNode)
                }
            }
            return;
        }
        e.stopImmediatePropagation();
        if (!confirm($(this).attr("conf"))) {
            e.preventDefault();
            if (e.originalEvent) {
                e.originalEvent.preventDefault();
            }
            e.stopPropagation();
            return false;
        }
    });
    // those links that have confFunc="ajaxify" are processed this way
    window['ajaxify'] = function (el) {
        const item = $(el);
        $.ajax({
            type: 'POST',
            url: item.attr('href'),
            data: { csrf_token: $('meta[name="csrf_token"]').attr('content') }
            // ...
        });
    };

    // Check if a selector has a given attribute
    const hasAttr = function (selector, attribute) {
        return (typeof selector.attr(attribute) !== 'undefined' &&
            selector.attr(attribute) !== false);
    }

    // Modal window confirmation box
    var $dialog = $('<div></div>')
        .dialog({
            autoOpen: false,
            modal: true
        });

    // Bind modal window to .confirm_modal class
    $('.confirm_modal').click(function (e) {
        e.preventDefault();
        var targetURL = $(this).attr('href');

        // Set window content
        $dialog.html($(this).attr('conf'));

        // Set title and button options
        var titleStr = hasAttr($(this), "title") ? $(this).attr("title") : "Confirm";
        var okStr = hasAttr($(this), "ok-str") ? $(this).attr("ok-str") : "Ok";
        var cancelStr = hasAttr($(this), "cancel-str") ? $(this).attr("cancel-str") : "Cancel";
        $dialog.dialog("option", "title", titleStr);
        $dialog.dialog("option", "buttons",
            [{
                text: okStr,
                click: function () {
                    $(this).dialog("close");
                    window.location.href = targetURL;
                }
            },
                {
                    text: cancelStr,
                    click: function () {
                        $(this).dialog("close");
                    }
                }]
        );

        // Launch dialog
        $dialog.dialog('open');
    });

    let addParamToQstring = function (url, param) {
        if (url.indexOf('?') === -1) {
            url += '?' + param;
        } else if (url.indexOf(param) === -1) {
            url += '&' + param;
        }
        return url;
    }

    // Bind modal window to .load_modal class
    $("body").append('<div id="modalLoad"></div>');
    $("#modalLoad").dialog({
        autoOpen: false,
        modal: true,
        buttons: {
            Close: function () {
                $(this).dialog("close");
            }
        }
    });
    $(".load_modal").click(function (e) {
        e.preventDefault();
        $("#modalLoad").html('<iframe id="modalIframe" width="100%" ' +
            'height="100%" marginWidth="0" marginHeight="0" frameBorder="0" ' +
            'scrolling="auto" />')
            .dialog("option", {
                "title": $(this).attr("title"),
                "height": Number($(this).attr("modal-height")),
                "width": Number($(this).attr("modal-width"))
            });
        const target_url = addParamToQstring($(this).attr("href"), "ajax=1");
        $("#modalIframe").attr("src", target_url);
        $("#modalLoad").dialog("open");
    });


    // Form 'select' field setting saver: cb=... is the url called, with argument
    // stat=option value;
    $("select.selSave")
        .attr("disabled", false)
        .change(function () {
            const idx = $(this).val();
            const sel = $(this).attr("disabled", true).append("<option id='ssav' selected>Saving...</option>");
            $.get($(this).attr("cb"),
                { stat: idx }, function (data) {
                    sel.children('#ssav').remove();
                    sel.children('option[value=' + data + ']')
                        .attr('selected', true).end()
                        .attr('disabled', false)
                    ;
                });
        });
    // Show arrows in random/lang pal boxes & enable callback functions
    $('.loadNext').show();
    //window.setTimeout(function(){nRfsh($('.usetNext'), 30000);}, 30000);

    // Submit forms using ctl-enter
    $(".ctlEntSub").keydown(function (e) {
        if (e.which === 17) {
            window["ctlDown"] = true;
        } else if (e.which === 13 && ctlDown) {
            window["ctlDown"] = false;
            if (confirm($(this).attr("conf"))) {
                $(this).parents("form").submit();
                return false;
            }
        } else {
            window["ctlDown"] = false;
        }
    });

    // Wall post deletion
    $('.delWPLink').show().click(function (e) {
        e.preventDefault();
        $(this).addClass('ajx_circ_16').css('padding-left', '20px')
            .html("Deleting...");
        ajxDelete(this, $(this).parents('.wall_post'));
    });

    // Jump to wall on profile page
    $('#wallLink').click(function (e) {
        $('#wall_textarea').focus();
        e.preventDefault();
    });

    // Place 'title' contents inside text .grayInput inputs and textareas
    $(function () {
        $('.grayInput').each(function () {
            var t = $(this),
                title = t.attr('title'),
                val = t.val();
            if (val.length > 0 && title != val) {
                t.removeClass('grayInput').addClass('nograyInput');
            } else if (typeof (title) !== "undefined") {
                this.value = title;
            }
        });
    });

    $('.grayInput').focus(function () {
        var t = $(this);
        if (t.val() == t.attr('title')) {
            t.val('').removeClass('grayInput').addClass('nograyInput');
        }
    }).blur(function () {
        var t = $(this);
        if (t.val() == '') {
            t.val(t.attr('title')).removeClass('nograyInput').addClass('grayInput');
        }
    });

    /*
     * Auto-growing textareas; technique ripped from Facebook
     */
    $.fn.autogrow = function () {
        this.filter('textarea').each(function () {

            var $this = $(this),
                minHeight = $this.height();
            var shadow = $('<div></div>').css({
                position: 'absolute',
                top: -10000,
                left: -10000,
                width: $(this).width() - parseInt($this.css('paddingLeft')) - parseInt($this.css('paddingRight')),
                fontSize: $this.css('fontSize'),
                fontFamily: $this.css('fontFamily'),
                lineHeight: $this.css('lineHeight'),
                resize: 'none'
            }).appendTo(document.body);
            var update = function () {
                var times = function (string, number) {
                    for (var i = 0, r = ''; i < number; i++) r += string;
                    return r;
                };
                var val = this.value.replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/&/g, '&amp;')
                    .replace(/\n$/, '<br/>&nbsp;')
                    .replace(/\n/g, '<br/>')
                    .replace(/ {2,}/g, function (space) {
                        return times('&nbsp;', space.length - 1) + ' ';
                    });
                shadow.html(val);
                $(this).css('height', Math.max(shadow.height() + 20, minHeight));
            };
            $(this).change(update).keyup(update).keydown(update);
            update.apply(this);
        });
        return this;
    };

    // Run only when markitup included
    if (typeof $.markItUp == 'function') {
        // Init all markitup textareas
        window.markitupSettings = {
            previewParserPath: '/async/bbcode_prev.php', // path to your BBCode parser
            markupSet: [
                { name: 'Bold', key: 'B', openWith: '[b]', closeWith: '[/b]' },
                { name: 'Italic', key: 'I', openWith: '[i]', closeWith: '[/i]' },
                { name: 'Underline', key: 'U', openWith: '[u]', closeWith: '[/u]' },
                { separator: '---------------' },
                { name: 'Picture', key: 'P', replaceWith: '[img][![Url]!][/img]' },
                {
                    name: 'Link',
                    key: 'L',
                    openWith: '[url=[![Url]!]]',
                    closeWith: '[/url]',
                    placeHolder: 'Your text to link here...'
                },
                { separator: '---------------' },
                {
                    name: 'Size', key: 'S', openWith: '[size=[![Text size]!]]', closeWith: '[/size]',
                    dropMenu: [
                        { name: 'Big', openWith: '[size=24]', closeWith: '[/size]' },
                        { name: 'Normal', openWith: '[size=12]', closeWith: '[/size]' },
                        { name: 'Small', openWith: '[size=9]', closeWith: '[/size]' }
                    ]
                },
                { separator: '---------------' },
                { name: 'Bulleted list', openWith: '[list]\n', closeWith: '\n[/list]' },
                //{name:'Numeric list', openWith:'[list=[![Starting number]!]]\n', closeWith:'\n[/list]'},
                { name: 'List item', openWith: '[*] ' },
                {
                    name: 'YouTube',
                    openWith: '[youtube][![Enter video code: http://www.youtube.com/watch?v=CODE: ]!]',
                    closeWith: '[/youtube]'
                },
                { separator: '---------------' },
                {
                    name: 'Colors', className: 'palette', dropMenu: [
                        { name: 'Yellow', openWith: '[color=#FCE94F]', closeWith: '[/color]', className: "col1-1" },
                        { name: 'Yellow', openWith: '[color=#EDD400]', closeWith: '[/color]', className: "col1-2" },
                        { name: 'Yellow', openWith: '[color=#C4A000]', closeWith: '[/color]', className: "col1-3" },

                        { name: 'Orange', openWith: '[color=#FCAF3E]', closeWith: '[/color]', className: "col2-1" },
                        { name: 'Orange', openWith: '[color=#F57900]', closeWith: '[/color]', className: "col2-2" },
                        { name: 'Orange', openWith: '[color=#CE5C00]', closeWith: '[/color]', className: "col2-3" },

                        { name: 'Brown', openWith: '[color=#E9B96E]', closeWith: '[/color]', className: "col3-1" },
                        { name: 'Brown', openWith: '[color=#C17D11]', closeWith: '[/color]', className: "col3-2" },
                        { name: 'Brown', openWith: '[color=#8F5902]', closeWith: '[/color]', className: "col3-3" },

                        { name: 'Green', openWith: '[color=#8AE234]', closeWith: '[/color]', className: "col4-1" },
                        { name: 'Green', openWith: '[color=#73D216]', closeWith: '[/color]', className: "col4-2" },
                        { name: 'Green', openWith: '[color=#4E9A06]', closeWith: '[/color]', className: "col4-3" },

                        { name: 'Blue', openWith: '[color=#729FCF]', closeWith: '[/color]', className: "col5-1" },
                        { name: 'Blue', openWith: '[color=#3465A4]', closeWith: '[/color]', className: "col5-2" },
                        { name: 'Blue', openWith: '[color=#204A87]', closeWith: '[/color]', className: "col5-3" },

                        { name: 'Purple', openWith: '[color=#AD7FA8]', closeWith: '[/color]', className: "col6-1" },
                        { name: 'Purple', openWith: '[color=#75507B]', closeWith: '[/color]', className: "col6-2" },
                        { name: 'Purple', openWith: '[color=#5C3566]', closeWith: '[/color]', className: "col6-3" },

                        { name: 'Red', openWith: '[color=#EF2929]', closeWith: '[/color]', className: "col7-1" },
                        { name: 'Red', openWith: '[color=#CC0000]', closeWith: '[/color]', className: "col7-2" },
                        { name: 'Red', openWith: '[color=#A40000]', closeWith: '[/color]', className: "col7-3" },

                        { name: 'Gray', openWith: '[color=#FFFFFF]', closeWith: '[/color]', className: "col8-1" },
                        { name: 'Gray', openWith: '[color=#D3D7CF]', closeWith: '[/color]', className: "col8-2" },
                        { name: 'Gray', openWith: '[color=#BABDB6]', closeWith: '[/color]', className: "col8-3" },

                        { name: 'Gray', openWith: '[color=#888A85]', closeWith: '[/color]', className: "col9-1" },
                        { name: 'Gray', openWith: '[color=#555753]', closeWith: '[/color]', className: "col9-2" },
                        { name: 'Gray', openWith: '[color=#000000]', closeWith: '[/color]', className: "col9-3" }
                    ]
                },
                { name: 'Quotes', openWith: '[quote]', closeWith: '[/quote]' },
                //{name:'Code', openWith:'[code]', closeWith:'[/code]'},
                { separator: '---------------' },
                //{name:'Clean', className:"clean", replaceWith:function(markitup) { return markitup.selection.replace(/\[(.*?)\]/g, "") } },
                { name: 'Preview', className: "preview", call: 'preview' },
            ]
        };
        $('TEXTAREA.markitup').markItUp(window.markitupSettings);
    }


    // City autocomplete
    var cityFields = $('input[name="_cityName"], input[name="_homeCityName"], input[name="cityName"], input[name="homeCityName"]');
    cityFields.each(function () {
        const t = $(this);
        if (typeof t.easyAutocomplete === 'function') {
            t.easyAutocomplete(genCityAcOptions(t))
        } else {
            console.error('easyAutocomplete is not defined');
        }
    });
});

// Save user setting to defaults
window["save_def"] = function (name, value, f) {
    var csrf_token = $('meta[name="csrf_token"]').attr('content');
    $.post('/app/helpers/saveDefault', 'def=' + name + '&v=' + value + '&csrf_token=' + encodeURIComponent(csrf_token), f);
};


// Init tooltips when doc ready
$(function () {
    $('[rel="tooltip"]').tooltip();
});
