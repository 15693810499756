window["removeBookmark"] = function (item) {
    var url = $(item).attr('href')
    $.ajax({
        url: url,
        success: function (data) {
            $(item).closest('.bmProfBox').remove()
            var count = $("#bookmarkCount").text()
            count--
            $("#bookmarkCount").text(count)
        }
    });
}
