// Use only in search.php

// Check/uncheck all cb's with given name
window["jqCheckAll"] = function (name, checked) {
    $("INPUT[@name=" + name + "][type='checkbox']")
        .attr('checked', checked ? true : false);
}

window["fnSearchStart"] = function () {
    $('.sCClpsd').show();
    $('.sCExpnd').show();
    $('.sCClpsd .title, .sCExpnd .title').click(function () {
        $(this).siblings('.sCFieldBody').toggle();
        const b = $(this).children('.expBtn');
        b.html(b.html() == '+' ? '-' : '+');
    });

    $('#ctrySel .selElem').change(function () {
        $('#sCContinents input:checked').attr('checked', false);
    });
    $('#sCMinArr').click(function () {
        $('#sCFields').hide();
        $('#sCMinArr').hide();
        $('#sCMinimized').show();
        $('#sCCtrlFlag').val(0);
    });
    $('#sCMinimized').click(function () {
        $('#sCMinimized').hide();
        $('#sCFields').show();
        $('#sCCtrlFlag').val(1);
        $('#sCMinArr').show();
    });

    $('#sCSave').click(function () {
        var data = escape($(this).parents('form').serialize());
        $("#sCSave").replaceWith('<span id="sCSaving" class="sSaving">Saving...</span>');
        save_def('search', data,
            function (d) {
                $("#sCSaving").replaceWith('<span class="sSaved">Saved</span>');
            }
        );
    });

    $('#sCReset').click(function () {
        $("#sCReset").replaceWith('<span id="sCReseting" class="sSaving">Resetting...</span>');
        save_def('search_reset', '',
            function (d) {
                $("#sCReseting").replaceWith('<span class="sSaved">Done</span>');
                window.location.href = '/app/search';
            }
        );
    });

}

if ((new RegExp('search.php')).test(window.location.href) ||
    (new RegExp('/app/search')).test(window.location.href)) {
    $(fnSearchStart);
}
