// Pop-up windows with jquery (http://www.djsipe.com/2008/07/04/doing-pop-ups-the-right-way-with-jquery/)
// Create a namespace for our utilities
window["UTIL"] = window.UTIL || {};
window.UTIL.popup = window.UTIL.popup || {};

window["clearAllNextSelect"] = function (cur_select) {
    return cur_select.nextAll("select")
        .each(function () {
            $(this).html('<option value=""></option>').hide();
        })
        .first();
}

window["modShortcut"] = function (button, cat_arr, show_child_select) {
    const num_selects = show_child_select ? cat_arr.length + 1 : cat_arr.length;

    for (var i = 0; i < num_selects; i++) {
        const sel = button.siblings().find("select[name^='todo\[" + i + "\]']"),
            cat_slice_end = i < cat_arr.length ? i : cat_arr.length,
            selected = i < cat_arr.length ? cat_arr[i] : undefined;

        modPopulateSelect(sel, cat_arr.slice(0, cat_slice_end), selected);
        sel.show();

        if (i === 0) clearAllNextSelect(sel);
    }
}

// Focus on first report's action select box
window["focusNextRep"] = function (cur_rep) {
    let next_rep;
    if (cur_rep === undefined) {
        next_rep = $("div:visible.userReport");
    } else {
        next_rep = cur_rep.nextAll(".userReport").first();
    }

    next_rep.find(".mod_actions_sel")
        .first()
        .focus();
};

window["modPopulateSelect"] = function (select_elem, levels, selectedIdx) {
    let cur_values = {};
    $.each(levels, function (i, v) {
        cur_values["levels[" + i + "]"] = v;
    });
    // Get new option set
    $.getJSON("/async/mod/report_queue.php", cur_values, function (j) {
        if (j !== null && !$.isEmptyObject(j)) {
            var options = '';
            var i = 0;
            $.each(j, function (val, name) {
                options += '<option value="' + val + '">' + i + ': ' + name + '</option>';
                i++;
            });
            select_elem.html(options).show().focus();
            if (selectedIdx !== undefined) {
                select_elem.val(selectedIdx);
                select_elem.attr("size", 1);
            } else {
                select_elem.attr("size", i);
                select_elem.val('');
            }
        } else {
            select_elem.html('<option value=""></option>').hide();
        }
    });
}

//
// Open popup window
//
// Opens a popup window using as little as a URL. An optional params object can
// be passed.
//
// @param {String} href
// @param {Object} params
// @return {WindowObjectReference}
//
UTIL.popup.open = function (href, params) {
    // Defaults (don't leave it to the browser)
    var defaultParams = {
        "width": "600",   // Window width
        "height": "420",   // Window height
        "top": "0",     // Y offset (in pixels) from top of screen
        "left": "0",     // X offset (in pixels) from left side of screen
        "directories": "no",    // Show directories/Links bar?
        "location": "no",    // Show location/address bar?
        "resizeable": "yes",   // Make the window resizable?
        "menubar": "no",    // Show the menu bar?
        "toolbar": "no",    // Show the tool (Back button etc.) bar?
        "scrollbars": "yes",   // Show scrollbars?
        "status": "no"     // Show the status bar?
    };

    var windowName = params["windowName"] || "new_window";

    var i, useParams = "";

    // Override defaults with custom values while we construct the params string
    for (i in defaultParams) {
        useParams += (useParams === "") ? "" : ",";
        useParams += i + "=";
        useParams += params[i] || defaultParams[i];
    }

    return window.open(href, windowName, useParams);
};


$(function () {
    var cats_novio = [3, 14];
    var cats_scams = [2, 27, 47];
    var cats_sex = [2, 27, 48];

    // Report page shortcuts
    $(".modact_novio").click(function () {
        modShortcut($(this), cats_novio);
    });

    $(".modact_scams").click(function () {
        modShortcut($(this), cats_scams, true);
    });
    $(".modact_sex").click(function () {
        modShortcut($(this), cats_sex, true);
    });


    $(".archive").click(function () {
        $(this).parents("form").find("input[name='todo']").val("archive");
    });
    $(".del_reports").click(function () {
        $(this).parents("form").find("input[name='todo']").val("del_reports");
    });
    $(".delete").click(function () {
        $(this).parents("form").find("input[name='todo']").val("delete");
    });
    $(".delScam").click(function () {
        $(this).parents("form").find("input[name='todo']").val("delete");
        $(this).parents("form").find("textarea[name='log_msg']").val("fast:scam");
    });
    $(".logMsgSubmit").click(function () {
        $(this).parents("form").find("input[name='todo']").val("log_msg");
    });
    $(".del_photo").click(function (e) {
        $(this).parents("form").find("input[name='todo']").val("delete_photo");
        $(this).parents("form").find("input[name='pid']").val($(this).attr('pid'));
        $(this).parents("form").submit();
        e.preventDefault();
    });
    $(".appr_photo").click(function (e) {
        $(this).parents("form").find("input[name='todo']").val("approve_photo");
        $(this).parents("form").find("input[name='pid']").val($(this).attr('pid'));
        $(this).parents("form").submit();
        e.preventDefault();
    });
    $(".reset_changes").click(function (e) {
        if (confirm($(this).attr('conf'))) {
            $(this).parents("form").find("input[name='todo']").val($(this).attr('name'));
            $(this).parents("form").submit();
        }
        e.preventDefault();
    });
    $(".show_del_options").click(function (e) {
        $(".modActDel").toggle();
        e.preventDefault();
    });
    $(".show_reset_options").click(function (e) {
        $(".modActReset").toggle();
        e.preventDefault();
    });

//  // "Clear all reports by this reporter"
//  $(".del_reports_by_user").click(function(e) {
//    $(this).parents("form").find("input[name='todo']'").val("del_reports_by_user");
//    $(this).parents("form").find("input[name='rep_uid']'").val($(this).attr('rep_uid'));
//    $(this).parents("form").submit();
//    e.preventDefault();
//  });
//
    // SUBMIT moderation action for a given report.
    // On success, hide the item. Otherwise, show an alert.
    $(".asyncReportForm").submit(function (e) {
        e.preventDefault();
        var f = $(this);
        var ta = f.find("[name=log_msg]");
        if (ta.val().replace(/(\n|\r|\s)+$/, '') == "Comments...") {
            ta.val('');
        }
        ta.addClass("ajx_load");
        var repForm = $(this);
        $.post("/mod/moderate.php?async=1", repForm.serialize(),
            function (data) {
                switch (data) {
                    case '0':
                        // Success: hide user reports
                        f.parents(".userReport").hide();
                        focusNextRep(repForm.parents("div.userReport"));
                        break;
                    case '1':
                        // Reload page
                        window.location.reload(true);
                        break;
                    default:
                        alert(data);
                }
                ta.removeClass("ajx_load");
            });
    });

    // Clear a flagged profile
    $(".clearFlagged").click(function (e) {
        e.preventDefault();
        var ele = $(this);
        $.get($(this).parents("form").find("input[name='clear_url']").val(),
            function () {
                ele.parents("div.acctFlag").hide();
            }
        );
    });

    // Show jQuery-dependent form elements once DOM is loaded
    $(".modActions").show();
    $(".flaggedForm").show();

    // Jump to url based on the value of a select element
    $(".selNav").removeAttr("disabled").change(function () {
        window.location = $(this).val();
    });


    // Load pop-up windows
    $(function () { // Run this code when document is done loading
        $("a.popup").each(function (i) {
            $(this).click(function (event) {
                event.preventDefault();
                var params = $(this).data("popup") || {};
                if ($(this).attr("target")) {
                    params.windowName = $(this).attr("target");
                }
                var windowObject = UTIL.popup.open(this.href, params);
                $(this).data("windowObject", windowObject);
            });
        });
        $(".del_apprv_links").show();
    });

    // Load moderation warning templates (mod/warn.php)
    $('.mwt_link').click(function (e) {
        $('#subject').val($(this).attr('title'));
        $('#msg').val($('#' + $(this).attr('href')).html() + ($('#mwt_base').html()));
        e.preventDefault();
    });
    $('#modWarnTplLinks').show();


    // Dynamically populate select elements
    $("select.mod_action").change(function () {
        $(this).attr("size", 1);

        // Clear all sub-selects & save next select ref
        let next_select = clearAllNextSelect($(this));

        // Display final warning checkbox if Warn is selected
        if ($(this).attr('name') == "todo[0]") {
            if ($(this).val() == 'warn') {
                $(this).nextAll('.fwarning').first().show();
            } else {
                $(this).nextAll('.fwarning').first().hide();
            }
        }

        // Get selected values for each select element
        let levels = [];
        $(this).parent().find("select.mod_action").each(function (i) {
            levels.push($(this).val());
        });
        modPopulateSelect(next_select, levels);
    });

    $(".logMsgTA").focus(function () {
        if ($(this).val().replace(/(\n|\r|\s)+$/, '') == "Comments...") {
            $(this).height("100px")
                .attr("color", "#000").val('');
        }
    });

    // Init user mod page modal dialog box
    $("body").append('<div id="modDialog" title="Moderate User"></div>');
    $("#modDialog").dialog({
        autoOpen: false,
        width: 800,
        height: 400,
    });
    $("body").append('<div id="modMsgsDialog" title="Messages"></div>');
    $("#modMsgsDialog").dialog({
        autoOpen: false,
        width: 630,
        height: 700,
    });

    // Bind to mod link
    $(".profModLink").click(function (e) {
        $("#modDialog").html('<iframe id="modIframe" width="100%" ' +
            'height="100%" marginWidth="0" marginHeight="0" frameBorder="0" ' +
            'scrolling="auto" />')
            .dialog("open");
        $("#modIframe").attr("src", "/mod/user.php?todo=modal&user_id=" +
            $(this).attr("user-id"));
        e.preventDefault();
    });

    // Bind to sent messages link
    $(".modMsgsLink").click(function (e) {
        $("#modMsgsDialog").html('<iframe id="modIframeMsgs" width="100%" ' +
            'height="100%" marginWidth="0" marginHeight="0" frameBorder="0" ' +
            'scrolling="auto" />')
            .dialog("open");
        $("#modIframeMsgs").attr("src", $(this).attr('href'));
        e.preventDefault();
    });

    // Photo moderation dialog
    $(".mod_photo").click(function (e) {
        let dialog_id = $(this).attr("div-id");
        const photo_dialog = $("#" + dialog_id);
        photo_dialog.dialog({
            autoOpen: false,
            width: 300,
            height: 200,
            title: "Moderate Photo",
            buttons: {
                "Submit": function () {
                    $.post("/mod/moderate.php?async=1",
                        $("#" + dialog_id + "_form").serialize(),
                        function (data) {
                            if (data != '0') {
                                alert("Error: " + data);
                            } else {
                                window.location.reload(true);
                            }
                        });
                    $(this).dialog("close");
                },
                "Cancel": function () {
                    $(this).dialog("close");
                }
            },
        });
        photo_dialog.dialog("open");
        e.preventDefault();
    });
});
