$(document).on("ready csrf-forms", function () {
    var token = $('meta[name=csrf_token]').attr('content');
    $('form').each(function (i, form) {
        var csrfTokenInput = $('input[name=csrf_token]', form);
        if (!csrfTokenInput.size()) {
            csrfTokenInput = $('<input name="csrf_token" type="hidden">');
            $(form).append(csrfTokenInput);
        }

        csrfTokenInput.val(token);
    });

    /** handle links that need confirmation before transition */
    const confirmHandler = function (e) {
        const get_params_from_href = function (href) {
            let params = {};
            $.each(href?.split('?', 2)[1]?.split('&') ?? [], function (i, value) {
                const split = value.split('=', 2) ?? [];
                params[split[0]] = split.length === 2 ? split[1] : '';
            });
            return params;
        }
        e.preventDefault();

        var params = get_params_from_href(this.href)
        params.csrf_token = $('meta[name="csrf_token"]').attr('content')
        const el = $(this);
        $.ajax({
            type: 'POST',
            url: el.attr('href'),
            data: params,
            complete: function (xhr, textStatus) {
                const locationHdr = xhr.getResponseHeader('location');
                if (locationHdr) {
                    window.location.href = locationHdr;
                } else {
                    window.location.reload(true);
                }
            }
        })

        return false;
    };
    // confirm before message delete
    $('a.cmtMsgLink').click(confirmHandler)
    // confirm before friend delete, etc.
    $('a.confirm').click(confirmHandler)
});
