$(function () {
    // Dynamic select menus + text links
    $(document).on('click', '.dynSelAdd', function (e) {
        const tmpl = $(this).siblings('.selTmpl').html();
        $(this).siblings('.selItems').append(tmpl);
    });

    $(document).on('click', '.delSelItem', function (e) {
        $(this).parents('.selItem').remove();
    });
});
