import imgNotFound from '@images/img-not-found.svg';

// Replace all images that had a 404 during loading with a placeholder
// This occurs frequently (at the moment) in development, because not all images
// were uploaded to minio.
//
// This is a workaround for the fact that we can't use the "onerror" attribute on img tags
// because it's not allowed in the CSP
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-onerror
export function handleImgErrors() {
    window.addEventListener('error', (event) => {
        if (event.target.tagName === 'IMG') {
            event.target.src = imgNotFound;
        }
    }, true);
}
