$(function () {
    $("#signinTopLink").toggle(
        function (e) {
            $("#signinBox").slideDown("fast").find("#login").focus();
            e.preventDefault();
        },
        function (e) {
            $("#signinBox").slideUp("fast");
            e.preventDefault();
        });
    $("#siClose").click(function () {
        $("#signinBox").slideUp("fast");
    });
});

window["setSigninBoxPos"] = function (parent, child) {
    var p = document.getElementById(parent);
    var c = document.getElementById(child);
    p["at_parent"] = p.id;
    c["at_parent"] = p.id;
    p["at_child"] = c.id;
    c["at_child"] = c.id;
    let left = 0;
    let top = p.offsetHeight + 2;
    for (; p; p = p.offsetParent) {
        top += p.offsetTop;
        left += p.offsetLeft;
    }
    c.style.top = top + 'px';
    c.style.left = left + 'px';
}
