$(function () {
    // Status message logic =================================
    // Save status message to the status callback
    $('#asEditForm').submit(function (e) {
        const semsg = $(this).children('#asEditMsg');
        semsg.attr("disabled", true);
        // Using .ajax with dataType: "json" to post data (status msg)
        // and access result as a json object
        let csrfToken = typeof window["_csrfToken"] !== 'undefined' ? window["_csrfToken"] : '';
        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: {
                todo: 'save',
                status_msg: semsg.val(),
                csrf_token: csrfToken
            },
            dataType: "json",
            success: function (data) {
                semsg.attr("disabled", false);
                $('#asMsg').text(data.msg);
                // Only hide input box if there's a non-empty status message
                if (data.msg !== '') {
                    $('#asEdit').hide();
                    $('#asCur').show();
                }
            }
        });
        e.preventDefault();
    });
    // Save link = submit
    $('#asEditSave').click(function () {
        $('#asEditForm').submit();
    });
    // Clear link sets a blank status
    $('#asEditClear').click(function () {
        $('#asEditMsg').val('');
        $('#asEditForm').submit();
    });
    // Any click in the current msg area shows edit box
    $('#asCur').click(function () {
        $(this).hide();
        $('#asEdit').show();
        $('#asEditMsg').focus();
    });

    $('#asEditMsg').focus(function () {
        $('#asMsgTmp').val($('#asEditMsg').val());
        $('#asEditMsg').val('');
    });
    $('#asEditMsg').blur(function () {
        if ($('#asEditMsg').val() == '') {
            $('#asEditMsg').val($('#asMsgTmp').val());
            $('#asMsgTmp').val('');
        }
    });

    // Cancel just shows current message box
    $('#asEditCancel').click(function () {
        $('#asEdit').hide();
        $('#asCur').show();
    });
    // Show ready objects
    $('#asEditLink').show();
    // End Status message logic =================================

    // Quick Search
    const qsRes = function () {
        $('#aQSResCont:hidden').show();
        $('#aQSTitle.collapsed').removeClass('collapsed').addClass('expanded');
        $('#aQSResults').load("/app/async/qsearch?" + $('#aQSForm').serialize())
    };
    // Fetch initial quick search results
    if (!$('#aQSTitle').hasClass("collapsed")) qsRes();
    $("#aQSForm :input").change(qsRes);
    // "More results" link
    $('#aQSSubmit').click(function (e) {
        $('#aQSForm').submit();
        e.preventDefault();
    });
    // Hide quick search results if collapsed
    $('#aQSTitle.collapsed').siblings('#aQSResCont').hide();

    $('#aQSTitle').click(function () {
        $(this).toggleClass('collapsed');
        $(this).toggleClass('expanded');
        $('#aQSResCont').toggle();
        save_def('qs_clpsd', Number($(this).hasClass('collapsed')), null);
    });
    $('#aQS').css('display', 'table');

    $('.showHiddenPart').click(function () {
        $(this).parent().find('.hiddenPart').show();
        $(this).hide();
        return false;
    });

    // Do some hacky positioning for facebook like button, since it needs to
    // be absolutely positioned for the pop-up windows to not get cut off.

    const fbtwit_pos = function () {
        var fbt = $("#fbtwit_pos");
        if (fbt.length) {
            const offset = fbt.offset();
            if (offset !== null) $("#fbtwit").css(offset);
        }
    };
    $(window).resize(fbtwit_pos);
    fbtwit_pos();
    $("#fbtwit").show();
});
