import 'core-js/stable';
import { handleImgErrors } from './modules/handleImgErrors';
import * as Sentry from '@sentry/browser';

// Initialize Sentry
Sentry.init({
    dsn: 'https://40e19bafa75d417ead2733a00ab3e30f@sentry.interpals.net/8',
    ignoreErrors:[
        "Non-Error exception captured",
        "Non-Error promise rejection captured"
    ],
});

Sentry.setTag('user_id', window.userId ?? 'none');

Sentry.setContext('user', {
    id: window.userId ?? 'none',
});

handleImgErrors();

console.log('v:5');
