window["addFileuploadError"] = function (msg) {
    var imgContainer = $('#img-container');
    var li = $('<li/>').appendTo(imgContainer);
    var title = $('<div/>').addClass('uploadFail').text(msg + ' ').appendTo(li);
    var cancelButton = $('<a/>').attr({
        href: '#cancel',
        'class': 'cancel',
        title: 'Remove'
    }).text('X').appendTo(title);

    cancelButton.click(function () {
        li.remove();
        return false;
    });

}

$(function () {
    if ($('.pageAlbums').length == 0) {
        return;
    }
    $(".albMoveToButton").addClass("clickable").click(function () {
        const pid = $(this).attr("photo_id"),
            tgt_id = $("#" + pid + "_mt"),
            caid = $("#cur_aid"),
            stat = $("#" + pid + "_mt_stat");

        stat.addClass("ajx_load").text("Moving...");

        $.get("/app/albums", {
                todo: "move",
                photo_id: pid,
                aid: tgt_id.val(),
                cur_aid: caid.val()
            },
            function (data) {
                stat.removeClass("ajx_load");
                if (data === "FULL") {
                    stat.addClass("ajx_err");
                    stat.html("<b>Error:</b> Target album is full.");
                } else if (data == "0") {
                    stat.addClass("ajx_err");
                    stat.html("<b>Error:</b> Internal server error occurred.");
                } else {
                    stat.parents(".albPhotoBox")
                        .addClass("closeable")
                        .html(data)
                        .children(".closer")
                        .click(function () {
                            $(this).parent(".closeable").hide();
                        });
                }
            });
    });
    $('#fupAddFile').addClass('clickable underlink_inline').click(function () {
        const slots = $('#file_slots'),
            new_total = Number(slots.val()) + 1,
            new_slot = new_total - 1;

        if (new_total > 10) {
            alert("You may only upload up to 10 photos at a time.");
        } else {
            $('#fupFiles').append("<br /><label>File #" + new_total + ":</label>" +
                "<input type='file' name='slot" + new_slot + "' class='fileUpload'>");
            slots.val(new_total);
        }
    });

    if (window.location.hash.indexOf(('#coverThumbEdit')) !== -1) {
        $('#coverThumbEditLink').click();
    }

});

// jCrop related

window["hideNewUpload"] = function () {
    $('#newupload').hide();
    $('#upload').show();
    return false;
}

// photo id with activated crop
window["active_crop"] = null;
// active jcrop instance
window["jcrop_api"] = null;

window["showCrop"] = function (photoId) {
    if (active_crop) {
        hide_crop(active_crop);
    }
    $('#albPhotoEditBox_' + photoId).hide();
    var cropbox = $('#cropBox_' + photoId);
    cropbox.show();
    var im = $('#ct_' + photoId),
        jcrop_params = {
            bgColor: 'black',
            bgOpacity: .4,
            aspectRatio: 1,
            boxWidth: 550,
            boxHeight: 550,
            keySupport: false,
            minSize: [cropbox.data('msx'), cropbox.data('msy')]
        },
        jcrop_callback = function () {
            // Thumb area in center
            var w = im.width(),
                h = im.height(),
                min_side = w > h ? h : w,
                half_ms = Math.floor(min_side / 2),
                cx = Math.floor(w / 2),
                cy = Math.floor(h / 2),
                x1 = cx - half_ms,
                y1 = cy - half_ms,
                x2 = cx + half_ms,
                y2 = cy + half_ms;

            // Use previous user settings if any
            if (im.data('tx') >= 0 && im.data('ty') >= 0 && im.data('ts') > 0) {
                x1 = Math.floor(im.data('tx'));
                y1 = Math.floor(im.data('ty'));
                x2 = x1 + Math.floor(im.data('ts'));
                y2 = y1 + Math.floor(im.data('ts'));
            }
            window["jcrop_api"] = this;
            jcrop_api.setOptions({ setSelect: [x1, y1, x2, y2] });
            window["active_crop"] = photoId;
            $.scrollTo(cropbox, 500);
        };

    // Image not initialized (fix for ie)
    if (!im.attr('src')) {
        im.load(function () {
            var initThumb = function () {
                im.removeClass('loading');
                if (typeof im.Jcrop === 'function') {
                    im.Jcrop(jcrop_params, jcrop_callback);
                } else {
                    console.error('Jcrop not loaded');
                }
            };

            // Cheat for IE 8, it fire 'loaded' for nor really loaded img
            if (!im.get(0).complete) {
                window.setTimeout(initThumb, 250);
            } else {
                // Normal browsers work ok
                initThumb.call();
            }

        })
            .error(function () {
                console.log("error loading image");
            })
            .addClass('loading')
            .attr("src", im.data("src"));
    } else {
        im.Jcrop(jcrop_params, jcrop_callback);
    }
    return false;
}

window["hide_crop"] = function (photoId) {
    window["active_crop"] = null;
    jcrop_api.destroy();
    $('#cropBox_' + photoId).hide();
    $('#albPhotoEditBox_' + photoId).show();
    return false;
}

window["rotateThumb"] = function (photoId, direction) {
    var postData = { csrf_token: $('meta[name="csrf_token"]').attr('content') };
    var params = {
        'todo': 'rotate',
        'aid': $('#cur_aid').val(),
        'pid': photoId,
        'direction': direction,
        'csrf_token': postData.csrf_token
    };

    // Hide photo edit details
    $('#albPhotoEditBox_' + photoId).hide();

    // Show "processing ..."
    $('#cropStatus_' + photoId).show();

    var err_div = '<div class="cropStatusErr">Error during processing, please reload page and try again.</div>';

    $.ajax({
        type: "POST",
        url: "/app/albums",
        data: params,
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.body) {
            $('#albPhotoBox_' + photoId).empty().html(data.body);
            if (data.cover) {
                $('.headThumb').find('img').attr('src', data.thumb)
            }
        } else {
            $('#cropStatus_' + photoId).empty().html(err_div);
        }
    }).fail(function (data, textStatus, jqXHR) {
        $('#cropStatus_' + photoId).empty().html(err_div);
    });
    return false;
}

window["generateThumb"] = function (photoId) {
    var sel = jcrop_api.tellSelect();
    if (!parseInt(sel.w)) {
        alert('Please select a crop region then press submit.');
        return false;
    }
    // Some fixes to ensure all numbers correct, seems jsCrop has some bugs
    var x = Math.floor(sel.x),
        y = Math.floor(sel.y);
    x = x < 0 ? 0 : x;
    y = y < 0 ? 0 : y;
    var postData = { csrf_token: $('meta[name="csrf_token"]').attr('content') };
    var params = {
        'todo': 'crop',
        'aid': $('#cur_aid').val(),
        'pid': photoId,
        'x': x,
        'y': y,
        'x2': Math.floor(sel.x2),
        'y2': Math.floor(sel.y2),
        'h': Math.floor(sel.h),
        'w': Math.floor(sel.w),
        'csrf_token': postData.csrf_token
    };
    hide_crop(photoId);
    // Hide photo edit details
    $('#albPhotoEditBox_' + photoId).hide();
    // Show "processing ..."
    $('#cropStatus_' + photoId).show();

    var err_div = '<div class="cropStatusErr">Error during processing, please reload page and try again.</div>';
    $.ajax({
        type: "POST",
        url: "/app/albums",
        data: params,
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.body) {
            $('#albPhotoBox_' + photoId).empty().html(data.body);
            if (data.cover) {
                $('.headThumb').find('img').attr('src', data.thumb)
            }
        } else {
            $('#cropStatus_' + photoId).empty().html(err_div);
        }
    }).fail(function (data, textStatus, jqXHR) {
        alert(JSON.stringify(data))

        $('#cropStatus_' + photoId).empty().html(err_div);
    });
    return false;
}

// HTML5 Uploader
$(function () {
    var imgContainer = $('#img-container');
    var dropBox = $('#drop-box');
    var fileInput = $('#file-field');
    var form = $('#newupload');

    var ua = navigator.userAgent.toLowerCase();
    var is_win_safari = ua.indexOf("safari/") !== -1 && ua.indexOf("windows") !== -1 && ua.indexOf("chrome/") == -1;

    // check support of File API, FormData и FileReader

    if (!$.support.fileSelecting || is_win_safari) {
        // No html5 file sending support - show old form
        form.remove();
        if (!is_win_safari) $('.oldbrowser').show();
        return false;
    }

    form.show();
    $('#upload').hide();

    fileInput.damnUploader({
        // Where to send
        url: form.prop('action'),
        // fake file input name (will be set as 'key' in $_FILES)
        fieldName: 'newuploadfile',
        // File dropbox element or selector
        dropBox: dropBox,
        // Max files to upload
        limit: 40,
        // Whn max files count exceed - this will be run
        onLimitExceeded: function () {
            addFileuploadError('The number of maximum files per upload was reached');
        },
        // manual file add event handler, for multiple files will be called for each
        // if return true file will be added to queie automatically
        onSelect: function (file) {
            // If not jpeg or filesize exceed - do not add
            if (!/image\/jpeg/.test(file.type)) {
                addFileuploadError('File [' + file.name + '] Is not a valid JPEG image');
                return false;
            }

            if (form.data('maxfilesize') < file.size) {
                addFileuploadError('File [' + file.name + '] size is too large');
                return false;
            }

            addFileToQueue(file);
            return false;
        },
        // When all files uploaded
        onAllComplete: function () {
        }
    });

    window["updateProgress"] = function (bar, value) {
        var width = bar.width();
        var bgrValue = -width + (value * (width / 100));
        bar.attr('rel', value).css('background-position', bgrValue + 'px center').text(value + '%');
    }

    //  Show selected files, show thumbs and manual add to upload queue
    window["addFileToQueue"] = function (file) {
        // creating li - add name, thumb and progress bar
        var imgContainer = $('#img-container');
        var li = $('<li/>').appendTo(imgContainer);
        var title = $('<div style="word-wrap: break-word;"/>').text(file.name + ' ').appendTo(li);
        var cancelButton = $('<a/>').attr({
            href: '#cancel',
            'class': 'cancel',
            title: 'Cancel upload'
        }).text('X').appendTo(title);

        var pBar = '';

        // If browser supports native html5 fileupload
        if (!file.fake) {

            // Add image and progress bar
            var img = $('<img/>').appendTo(li);
            pBar = $('<div/>').addClass('progress').attr('rel', '0').text('Uploading...').appendTo(li);

            // create FileReader and show img thumb
            if ($.support.fileReading) {
                var reader = new FileReader();
                reader.onload = (function (aImg) {
                    return function (e) {
                        aImg.attr('src', e.target.result);
                        aImg.attr('width', 176); // Max box size
                    };
                })(img);
                reader.readAsDataURL(file);
            }
        } else {
        }

        // Create upload object
        var uploadItem = {
            file: file,
            onProgress: function (percents) {
                if ($.support.uploadControl) updateProgress(pBar, percents);
            },
            onComplete: function (successfully, data, errorCode) {
                // Show upload errors
                var uerr = function (msg) {
                    title.empty().append($('<div/>')).addClass('uploadFail').html(msg + ' ');
                    $('<a/>').attr({
                        href: '#cancel',
                        'class': 'cancel',
                        title: 'Remove'
                    }).text('X').click(function () {
                        li.remove();
                        return false;
                    }).appendTo(title);
                };

                if (successfully) {
                    // try to decode response
                    var s = '';
                    try {
                        s = jQuery.parseJSON(data);
                    } catch (e) {}

                    if (typeof s.error != 'undefined') {
                        uerr(s.error);
                    } else if (typeof s.html != 'undefined') {
                        // remove box and add album item
                        li.remove();
                        $('.albPhotoList').prepend(s.html);
                    } else {
                        uerr('Server error, please try again later');
                    }
                } else {
                    if (!this.cancelled) {
                        switch (errorCode) {
                            case 0 :
                                uerr('Connection problem please try again');
                                break;
                            case 404 :
                            case 500 :
                                uerr('Server error, please try again later');
                                break;
                            default:
                                uerr('Unknown error please try again');
                                break;
                        }
                    }
                }
            }
        };

        var postData = { csrf_token: $('meta[name="csrf_token"]').attr('content') };
        if (uploadItem.addPostData) {
            uploadItem.addPostData(postData);
        }

        // Add to queue
        var queueId = fileInput.damnUploader('addItem', uploadItem);

        // 'Cancel' click handler
        cancelButton.click(function () {
            fileInput.damnUploader('cancel', queueId);
            li.remove();
            return false;
        });

        // Start upload automatically
        fileInput.startUploadItem(queueId);

        return uploadItem;
    }

    // dropBox init
    dropBox.bind({
        dragenter: function () {
            $('.dropbox-text').addClass('highlighted');
            return false;
        },
        dragover: function () {
            return false;
        },
        dragleave: function () {
            $('.dropbox-text').removeClass('highlighted');
            return false;
        },
        drop: function () {
            $('.dropbox-text').removeClass('highlighted');
            return false;
        }
    });

});
