// Add timeNow to = function Date - this will print formatted time
Date.prototype.timeNow = function () {
    return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" +
        ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes() + ":" +
        ((this.getSeconds() < 10) ? "0" : "") + this.getSeconds();
};

window.pm_ajax_request_active = 0;
window.messages_highlighted = 1;
window.last_ads_reload = new Date().getTime();
// Avoid multiple calls of load more threads called by scroll
window.load_more_threads_active = 0;
// Avoid multiple calls of load_more_msg
window.load_more_msg_active = 0;
// Array of drafts
window.last_draft = {};

const err_div = $('<div style="color:red"></div>');
const err_txt = 'Error during processing, please reload page and try again.';

const isMarkitUpLoaded = function () {
    return typeof $.markItUp === 'function';
}

// Onload
$(function () {
    if ($('.privateMessages').length === 0) {
        return;
    }
    pm_ajax_request_active = 0;
    messages_highlighted = 1;
    last_ads_reload = new Date().getTime();
    // Avoid multiple calls of load more threads called by scroll
    load_more_threads_active = 0;
    // Avoid multiple calls of load_more_msg
    load_more_msg_active = 0;
    // Array of drafts
    last_draft = {};

    $('#message').focus();
    // Detect view mode
    window.pm_view_mode = $('#threads_main').hasClass('paged') || $('#single_thread_wrapper').length ? 'paged' : 'chat';

    if (pm_view_mode === 'paged') {
        // Load thread on click
        $(document).on('click', '.pm_thread', function (e) {
            // Do not run when clicked on buttons
            if ($(e.target).hasClass('fa')) return false;
            if ($(e.target).hasClass('thumb')) return true;

            // Get thread ID
            var id = $(this).attr('id').replace('thread_', '');

            window.location.href = window.location.protocol + '//' +
                window.location.hostname + window.location.pathname +
                '?thread_id=' + id;
            return false;
        });

        // Remove highlighting from new messages in 3s
        window.setTimeout(function () {
            window.remove_new_mark();
        }, 3000);

        // If user push back btn in classic view after using split view
        if (htm5_history_supported) {
            // Listen to htm5 history events
            window.addEventListener('popstate', function (e) {
                if (e.state) {
                    // Redirect to url in path (simulate browser history)
                    let loc = window.location.protocol + '//' +
                        window.location.hostname + window.location.pathname;
                    if (typeof e.state.thread_id != 'undefined' && !isNaN(e.state.thread_id) &&
                        (e.state.thread_id != '')) {
                        loc += '?thread_id=' + e.state.thread_id;
                    }
                    window.location.href = loc;
                }
                return false;
            }, false);
        }
    }

    // Load only for single thread view
    if ($('#single_thread_wrapper').length) {
        $('#pm_message').autogrow();

        // Submit on ctrl + enter
        if (isMarkitUpLoaded()) {
            window.markitupSettings.onCtrlEnter =
                {
                    keepDefault: false,
                    openWith: '',
                    closeWith: '',
                    beforeInsert: function () {
                        window.send_msg_paged();
                    }
                };
            $('#pm_message').markItUp(window.markitupSettings);
        }
    } else {
        // All other pages contains control bar
        // Run filter by username on enter
        $(document).on('keyup', '#username_filter', function (e) {
            if (e.keyCode === 13) {
                window.find_contact();
            }
        });
        // Run add contact on enter
        $(document).on('keyup', '#new_contact', function (e) {
            if (e.keyCode === 13) {
                window.add_contact();
            }
        });
    }

    // Regular updates
    window.run_update_process();

    // Chat view mode related functions
    if (pm_view_mode === 'chat') {
        // Load more messages on scroll up or "Load more" link click
        $('#threads_right').scroll(function () {
            // Avoid multiple calls
            if (load_more_msg_active == 1) return;
            if ($(this).scrollTop == 0) window.load_more_msg();
        });
        $(document).on('click', '#load_older_messages', window.load_more_msg);

        // Remove "new" in 3 seconds
        window.setTimeout(function () {
            window.remove_new_mark();
        }, 3000);

        // Init markitup
        if (isMarkitUpLoaded()) {
            window.markitupSettings.resizeHandle = false;
            // Submit on ctrl + enter
            window.markitupSettings.onCtrlEnter =
                {
                    keepDefault: false,
                    openWith: '',
                    closeWith: '',
                    beforeInsert: function () {
                        window.send_msg();
                        window.setTimeout(function () {
                            $('#message').blur();
                        }, 75);
                    }
                };
            // Remove preview btn
            window.markitupSettings.markupSet.splice(16, 1);
            // Add resize btn
            window.markitupSettings.markupSet.push({
                name: 'Resize textarea', className: "resize", call: function () {
                    $('#threads_main').toggleClass('big_msg');
                }
            });
            $('#message').markItUp(window.markitupSettings);
        }

        // Resize markItUp box on focus
        $('#message:not(.send)').on('keypress', function () {
            let $tr = $('#threads_right'),
                tr = $tr.get(0),
                /* If messages was scrolled to bottom - set trigger
                 to scroll to bottom after Markitup show */
                scroll_to_bottom = (tr.scrollHeight == tr.scrollTop + $tr.height());
            // Show markitup
            $('#threads_main').addClass('send');
            if (scroll_to_bottom) {
                window.setTimeout(function () {
                    let tr = $('#threads_right').get(0);
                    tr.scrollTop = tr.scrollHeight - $tr.height();
                }, 25);
            }

            // Remove highlighting from new messages
            // window.remove_msg_highlighting();

        }).on('blur', function () {
            if (isMarkitUpLoaded()) {
                window.markitup_timeout =
                    setTimeout(function () {
                        if ($('#message').val().length) return;
                        $('#threads_main').removeClass('send');
                    }, 100);
            }
        });

        // Do not clear markitup if user clicked on markitup header but text empty
        $(document).on('click', '.markItUpHeader', function () {
            let m = $('#message');
            if (m.val().length === 0) {
                // Set whitespace to avoid collapsing
                m.val(' ');
            }
            clearTimeout(window.markitup_timeout);
        });

        // Load thread on click
        $(document).on('click', '.pm_thread', function (e) {
            // Do not run when clicked on buttons or '.thumb'
            if ($(e.target).hasClass('fa')) return false;
            if ($(e.target).hasClass('thumb')) return true;

            // Get thread ID
            let id = $(this).attr('id').replace('thread_', '');

            // If html5 history not supported use redirect
            if (!htm5_history_supported) {
                window.location.href = window.location.protocol + '//' +
                    window.location.hostname + window.location.pathname +
                    '?thread_id=' + id;
                return false;
            }

            // Push url to html5 history
            let stateObj = { 'thread_id': id };
            history.pushState(stateObj, null, '/pm.php?thread_id=' + id);

            window.view_thread(id, true);
        });

        if (htm5_history_supported) {
            // Listen to htm5 history events
            window.addEventListener('popstate', function (e) {
                // Jump to thread in path (simulate browser history)
                if (e.state) {
                    if (typeof e.state.thread_id != 'undefined' && !isNaN(e.state.thread_id) &&
                        (e.state.thread_id != '')) {
                        window.view_thread(e.state.thread_id, true);
                        return false;
                    }
                }
                window.close_thread(true);

                return false;
            }, false);
        }

        // Load older conversations on scroll down
        $('#threads_left').scroll(function () {
            // If no btn - return
            if (load_more_threads_active == 1 || $('#load_more_conversations').length == 0) return;
            let cont = $(this);
            // Begin loading more even before we get to end
            if ((cont.scrollTop + 500) > (this.scrollHeight - cont.height())) {
                // Avoid multiple calls
                window.load_older_conversations();
            }
        });

        // Resize to fit screen
        window.threads_main_resize();

        if (!is_mobile_browser()) {
            // Detect resize end and run resize func
            let rtime = new Date(1, 1, 2000, 12, 0, 0);
            let timeout = false;
            let delta = 500;
            $(window).resize(function () {
                rtime = new Date();
                if (timeout === false) {
                    timeout = true;
                    setTimeout(window.resizeend, delta);
                }
            });

            window.resizeend = function () {
                if (new Date() - rtime < delta) {
                    setTimeout(window.resizeend, delta);
                } else {
                    timeout = false;
                    window.threads_main_resize();
                }
            }
        }

        // If open active thread by direct link
        let actt = $('#threads_left .pm_thread.active');
        if (actt.length) {
            //Scroll '#threads_left' and put active thread in the middle
            window.scroll_thread_to_middle(actt);
            //Scroll '#threads_right' to bottom
            let trt = $('#threads_right');
            window.setTimeout(function () {
                if (typeof trt.scrollTo === 'function') {
                    trt.scrollTo(trt.get(0).scrollHeight);
                }
            }, 200);
        }
    } // Chat view related functions END
});

window.remove_msg_highlighting = function () {
    if (window.messages_highlighted === 0) return;
    if ($('#single_thread_wrapper').length > 0) {
        $('#conversation').find('.pm_new').removeClass('pm_new');
    } else {
        $('#threads_right').find('.pm_new').removeClass('pm_new');
    }
    window.messages_highlighted = 0;
}

window.get_newest_msg_id_in_thread = function () {
    let lm;
    if ($('#single_thread_wrapper').length > 0) {
        // Classic view
        lm = $('#conversation').find('.pm_msg:first');
    } else {
        // Split view
        lm = $('#threads_right').find('.pm_msg:last');
    }
    if (lm.length) {
        return lm.attr('id').replace('msg_', '');
    }
    return false;
}

window.get_draft = function () {
    let single_thread = $('#single_thread_wrapper').length > 0,
        m = $.trim($(single_thread ? '#pm_message' : '#message').val()),
        res = {},
        at = window.get_active_thread_id(),
        writing_msg = single_thread ? $('#pm_message').is(':focus') : $('#threads_main').hasClass('send'),
        // Update draft on server, if user in edit mode & no msg in drafts cache, or it's different.
        update_needed = writing_msg && at && (typeof last_draft[at] == 'undefined' || last_draft[at] != m);

    if (update_needed) {
        last_draft[at] = res.message = m;
        // Write info that msg was autosaved
        let currentdate = new Date(),
            txt = 'Autosaved at ' + currentdate.timeNow();
        $('#markitup_msg').empty().html(txt);
    }
    return res;
}

// Get active page in classic single thread view
window.get_active_page = function () {
    let c = $('#conversation');
    if (c.length && $.isNumeric(c.data('page'))) return c.data('page');
    return false;
}

// unused?
window.update_pm_info_paged = function () {
    if (window.pm_ajax_request_active) {
        // Run process again in 1 sec
        window.run_update_process(1000);
        return;
    }

    // If not on first page we dont update threads and messages list
    if (window.get_active_page() != 1) {
        window.run_update_process();
        return;
    }

    let stw = $('#single_thread_wrapper'),
        viewing_thread = stw.length > 0,
        tl = $('#threads_left'),
        options = {
            'action': 'update_pm_info_paged',
            // TS for user stats
            'ts': window.cur_ts
        };

    if (viewing_thread) {
        // Thread id to get new messages
        options.thread = window.get_active_thread_id();
        // Find newest message
        options.newest_msg_id = window.get_newest_msg_id_in_thread();
        // Save not sent message
        options.draft = window.get_draft();
    } else {
        // max_msg_id to get thread list changes
        options.max_msg_id = tl.data('max-msg-id');
    }

    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: options,
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data?.not_logged_in == 1) {
            window.location.href = window.location.protocol + '//' +
                window.location.hostname + window.location.pathname;
            return;
        }

        // Update member stats if any
        if (typeof data.member_stats != 'undefined') {
            update_success(data.member_stats, 'success');
        }

        let c = $('#conversation');

        if (viewing_thread) {
            // If new messages arrived
            if (typeof data.new_messages != 'undefined' && data.new_messages.length > 0) {
                window.messages_highlighted = 1;
                // Remove "new" in 3 seconds
                window.setTimeout(function () {
                    window.remove_new_mark();
                }, 3000);

                c.prepend(data.new_messages);
                // If more than 25 msgs - remove
                c.find('.pm_msg:gt(24)').remove();
            }

            // Update "last opened by contact" timestamp
            if (typeof data.lobc != 'undefined') {
                /*
                 Remove "unread" mark
                 Check if timestamp changed
                 Microseconds needed
                 */
                let l = c.data('lobc');
                // Update only if TS changed
                if (data.lobc > 0 && l < data.lobc) {
                    c.data('lobc', data.lobc);
                    // Remove unread status
                    c.find('.pm_unread').removeClass('pm_unread');
                }
            }
        } else {
            // If threads list changed
            if (data.threads_update && data.max_msg_id) {
                // Update 'max_msg_id'
                tl.data('max-msg-id', data.max_msg_id);
                tl.empty();
                tl.append(data.threads_update);
            }
        }
    }).always(function () {
        // Run again
        window.run_update_process();
    });
}

window.update_pm_info = function () {
    // Don`t run if ajax request is in progress
    // because data can change when ajax request finishes

    /* RUN ONLY FOR 1 TAB ??????  */
    if (window.pm_ajax_request_active) {
        // Run process again in 1 sec
        window.run_update_process(1000);
        return;
    }

    let threadsLeft = $('#threads_left'),
        filtersBlock = $('#filters'),
        filter = filtersBlock.data('filter'),
        newestMsgIdInThread = window.get_newest_msg_id_in_thread();

    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'update_pm_info',
            // Send this to get new messages
            'thread': window.get_active_thread_id(),
            // Active filter
            'filter': filter,
            // Find newest message
            'newest_msg_id': newestMsgIdInThread,
            // Send this to get thread list changes
            'max_msg_id': threadsLeft.data('max-msg-id'),
            'threads_count': threadsLeft.find('.pm_thread').length,
            // Save not sent message
            'draft': window.get_draft(),
            // TS for user stats
            'ts': window.cur_ts
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        // cast as int
        if (data.not_logged_in == 1) {
            window.location.href = window.location.protocol + '//' +
                window.location.hostname + window.location.pathname;
            return;
        }

        // Update member stats if any
        if (typeof data.member_stats != 'undefined') {
            update_success(data.member_stats, 'success');
        }

        // If threads list changed
        if (data.threads_update && data.max_msg_id) {
            // Update 'max_msg_id'
            threadsLeft.data('max-msg-id', data.max_msg_id);

            let activeThreadId = window.get_active_thread_id();
            threadsLeft.empty();
            threadsLeft.append(data.threads_update);

            if (activeThreadId && $('#thread_' + activeThreadId).length) {
                // If threads was reloaded and there was active thread -
                // set active thread again, without reloading messages
                window.view_thread(activeThreadId, false);
            }
        }

        let activeThreadId1 = window.get_active_thread_id();
        // Update only if thread_id same
        if (typeof data.thread_id == 'undefined' || data.thread_id != activeThreadId1) return;

        // Update "last opened by contact" timestamp
        if (typeof data.lobc != 'undefined') {
            window.update_unread(activeThreadId1, data.lobc, data.new_messages.length);
        }

        // If new messages arrived
        if (data.new_messages) {
            window.messages_highlighted = 1;
            // Remove "new" in 3 seconds
            window.setTimeout(function () {
                window.remove_new_mark();
            }, 3000);

            // Find last date divider
            let threadsRight = $('#threads_right'),
                first_datediv = threadsRight.find('.pm_date_div:last');

            // Append new messages to message list
            window.append_msgs(data.new_messages, threadsRight);

            // Remove dup date dividers
            window.setTimeout(function () {
                // Chk for same date divider in loaded messages
                let date = first_datediv.data('date'),
                    last_datediv = $('#threads_right').find('div[data-date=' + date + ']:last');
                if (last_datediv.length > 0) {
                    // Remove duplicate old
                    last_datediv.remove();
                }
                if (typeof threadsRight.scrollTo === 'function') {
                    threadsRight.scrollTo(threadsRight.get(0).scrollHeight);
                }
            }, 200);

        }
    }).always(function () {
        // Run again
        window.run_update_process();
    });
}

window.append_msgs = function (msg_data, target) {
    let msgs_jq = jQuery(msg_data);

    // Remove previous "last online" blurbs
    $('div.msg_last_online').remove();

    // Collapse messages from same user
    let last_user = $('#threads_right').find('.msg_username:last').text();
    if (last_user === msgs_jq.find('.msg_username:first').text()) {
        msgs_jq.find('.msg_user:first').remove();
        msgs_jq.find('.msg_user_thumb:first').remove();
    }

    target.append(msgs_jq);
}

window.remove_new_mark = function () {
    if ($('#single_thread_wrapper').length > 0) {
        // Classic view
        $('#conversation').find('.pm_new').removeClass('pm_new');
    } else {
        // Split view
        $('#threads_right').find('.pm_new').removeClass('pm_new');
    }
}

window.update_unread = function (thread_id, lobc, force_check) {
    let thread = $('#thread_' + thread_id);
    force_check = typeof force_check != 'undefined';
    // Check if timestamp changed
    // Microseconds needed
    let l = thread.data('lobc');
    // Update only if TS changed OR force_check = true
    if (force_check || (lobc > 0 && l < lobc)) {
        thread.data('lobc', lobc);
        // Remove unread status
        $('#threads_right').find('.pm_unread').removeClass('pm_unread');
    }
}

window.run_update_process = function (interval) {
    if (typeof interval == 'undefined') {
        // Run again = function in "interval" after this run finished.
        // We use old online update interval value
        interval = typeof window.online_update_interval == "number" ? window.online_update_interval : 6000;
    }

    const updateFn = window.pm_view_mode === 'paged' ? window.update_pm_info_paged : window.update_pm_info;
    window.setTimeout(updateFn, interval);
}

window.add_contact = function () {
    let btn = $('#add_contact'),
        err_txt = 'Error when adding new contact. Try again.',
        nc = $('#new_contact'),
        username = $.trim(nc.val());

    if ($('#new_contact:hidden').length) {
        nc.show();
        nc.animate({ 'width': '90px', 'margin-left': '-4px' }, 100);
        $('#add_contact_link').hide();
        $('#new_contact_button').show();
        return;
    }

    if (username.length < 3 || nc.attr('title') === username) {
        // Avoid double enter press after alert
        nc.blur();
        alert('Username must be at least three characters.');
        return false;
    }

    if (!username.length) {
        alert('Please enter new contact username');
        return false;
    }

    btn.prop("disabled", true);

    // Set ajax request marker
    window.pm_ajax_request_active = 1;

    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'add_contact',
            'username': username
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.success) {
            // Redirect to new thread
            if (pm_view_mode === 'paged') {
                window.location.href = window.location.protocol + '//' +
                    window.location.hostname + window.location.pathname +
                    '?thread_id=' + data.thread_id;
                return false;
            }
            $('#new_contact').val('');
            // Remove duplicate thread id if any
            $('#thread_' + data.thread_id).remove();
            // Add just rendered thread
            $('#threads_left').prepend(data.body);
            // Make new thread active
            window.view_thread(data.thread_id, true);
        } else {
            if (data.error) {
                alert(data.error);
            } else {
                alert(err_txt);
            }
        }
    }).fail(function (data, textStatus, jqXHR) {
        alert(err_txt);
    }).always(function () {
        // Unset ajax request marker
        window.pm_ajax_request_active = 0;
        btn.prop("disabled", false);
    });

    return false;
}

window.get_active_thread_id = function () {
    const re = /thread_id=(\d+)/;
    const rr = re.exec(window.location.href);

    if (rr && typeof rr != 'undefined' && typeof rr[1] != 'undefined' && $.isNumeric(rr[1])) {
        return rr[1];
    }
    return false;
}

window.delete_message = function (id) {
    if (!confirm('Are you sure you want to delete this message?')) return;

    const err_txt = 'Error when deleting message. Try again.';
    const btn = $('#del_msg_btn_' + id),
        tid = window.get_active_thread_id();

    if (!$.isNumeric(tid)) {
        window.location.reload();
        return false;
    }

    const cogrot = $('<i class="fa fa-cog cog-rot"/>');
    btn.hide();
    btn.after(cogrot);

    // Set ajax request marker
    window.pm_ajax_request_active = 1;
    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'delete_message',
            // Get thread id from active.thread div
            'thread': tid,
            'message_id': id
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.success) {
            // Remove msg div
            $('#msg_' + id).remove();
            if (window.pm_view_mode === 'chat') {
                // Remove date divider if no msgs for this date
                const last = $('#threads_right').children().last();
                if (last.hasClass('pm_date_div')) {
                    last.remove();
                }
            }
        } else {
            if (data.error) {
                alert(data.error);
            } else {
                alert(err_txt);
            }
        }
    }).fail(function (data, textStatus, jqXHR) {
        alert(err_txt);
    }).always(function () {
        // Unset ajax request marker
        window.pm_ajax_request_active = 0;
        cogrot.remove();
        btn.show();
    });
    return false;
}

window.mark_all_as_read = function () {
    if (!confirm('Mark all new messages as "Read" ?')) return false;

    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: { 'action': 'mark_all_as_read' },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        // Redirect
        window.location.href = window.location.protocol + '//' +
            window.location.hostname + window.location.pathname;
    }).always(function () {
        // Unset ajax request marker
        window.pm_ajax_request_active = 0;
    });
    return false;
}

window.delete_thread = function (t, id, block_user) {
    const err_txt = 'Error when deleting conversation. Try again.';

    if (!confirm($(t).attr('title') + '?')) return;

    // Set ajax request marker
    window.pm_ajax_request_active = 1;
    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'delete_thread',
            'thread': id,
            'block_user': block_user
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.success) {
            // Remove thread div
            $('#thread_' + id).remove();
            if (pm_view_mode === 'chat') {
                // Hide right part
                $("#threads_main").removeClass('full');
                if (typeof $.scrollTo === 'function') {
                    $.scrollTo($(".cCont"));
                }
                $('#threads_right').empty();
            }
        } else {
            if (data.error) {
                alert(data.error);
            } else {
                alert(err_txt);
            }
        }
    }).fail(function (data, textStatus, jqXHR) {
        alert(err_txt);
    }).always(function () {
        // Unset ajax request marker
        window.pm_ajax_request_active = 0;
    });
    return false;
}

window.report_message = function (id) {
    const wl = window.location;
    wl.href = wl.protocol + '//' + wl.host + '/report.php?todo=msg&msg_id=' + id + '&thread_id=' + window.get_active_thread_id();
}

window.send_msg_paged = function () {
    let m = $('#pm_message'),
        msg = $.trim(m.val()),
        c = $('#conversation'),
        at = c.data('thread-id');

    if (msg.length === 0) {
        alert('Cannot send empty message.');
        return;
    }

    if (!$.isNumeric(at)) {
        window.location.reload();
        return false;
    }

    // Del draft in js cache
    if (typeof last_draft[at] != 'undefined') {
        delete last_draft[at];
    }

    // Disable textarea and submit
    $('#msg_submit, #pm_message').prop("disabled", true);
    $('#msg_submit').val('Sending');

    var err_txt = 'Error when sending message. Try again.';

    // Set ajax request marker
    window.pm_ajax_request_active = 1;
    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'send_message',
            // Get thread id from active.thread div
            'thread': at,
            'message': msg
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.success) {
            if (c.data('page') == 1) {
                // Add new message
                c.prepend(data.body);
                // If more then 25 msgs - remove
                c.find('.pm_msg:gt(24)').remove();
            }
            // Remove "No messages if any"
            var nm = c.find('.no_messages');
            if (nm.length) nm.remove();
            // Clear and collapse textarea
            m.val('');
            // Remove auotosaved msg
            $('#markitup_msg').empty();
        } else {
            if (data.error) {
                alert(data.error);
            } else {
                alert(err_txt);
            }
        }
    }).fail(function (data, textStatus, jqXHR) {
        alert(err_txt);
    }).always(function () {
        // Unset ajax request marker
        pm_ajax_request_active = 0;
        // Enable textarea and submit
        $('#msg_submit, #pm_message').prop("disabled", false);
        $('#msg_submit').val('Send');
    });
    return false;
}

window.send_msg = function () {
    let msgBlock = $('#message'),
        msgText = $.trim(msgBlock.val()),
        threadsRight = $('#threads_right'),
        threadsMain = $('#threads_main'),
        threadId = $('#send_thread_id').val();

    if (msgText.length === 0) {
        alert('Cannot send empty message.');
        return;
    }

    if (!$.isNumeric(threadId)) {
        window.location.reload();
        return false;
    }

    // Del draft in js cache
    if (typeof last_draft[threadId] != 'undefined') {
        delete last_draft[threadId];
    }

    // Disable textarea and submit
    $('#msg_submit, #message').prop("disabled", true);
    $('#msg_submit').val('Sending');

    var err_txt = 'Error when sending message. Try again.';

    // Set ajax request marker
    window.pm_ajax_request_active = 1;
    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'send_message',
            // Get thread id from active.thread div
            'thread': threadId,
            'message': msgText
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.success) {
            // Add new message
            window.append_msgs(data.body, threadsRight);

            // Remove "No messages if any"
            let nm = threadsRight.find('.no_messages');
            if (nm.length) nm.remove();

            // Scroll to bottom
            window.setTimeout(function () {
                if (typeof threadsRight.scrollTo === 'function') {
                    threadsRight.scrollTo(threadsRight.get(0).scrollHeight);
                }
            }, 200);

            // Clear and collapse textarea
            msgBlock.val('');

            // Remove auotosaved msg
            $('#markitup_msg').empty();

            // This class enlarges markitUp textarea
            threadsMain.removeClass('send');
            window.focus_msg_box();
        } else {
            if (data.error) {
                alert(data.error);
            } else {
                alert(err_txt);
            }
        }
    }).fail(function (data, textStatus, jqXHR) {
        alert(err_txt);
    }).always(function () {
        // Unset ajax request marker
        window.pm_ajax_request_active = 0;
        // Enable textarea and submit
        $('#msg_submit, #message').prop("disabled", false);
        $('#msg_submit').val('Send');
    });

    return false;
}

window.focus_msg_box = function () {
    // Focus on msg textarea.
    // For some reason doesn't always work if we do it immediately, so add a timeout.
    window.setTimeout(function () {
        $('#message').focus();
    }, 200);
}

window.load_more_msg = function () {
    let thread_id = window.get_active_thread_id();
    if (!thread_id) return;
    load_more_msg_active = 1;
    window.reload_ads();
    let t = $('#threads_right');
    // Return if no "load more" btn
    if ($('#load_older_messages').length === 0) {
        window.load_more_msg_active = 0;
        return;
    }
    $('#mr_loading, #load_older_messages').remove();
    // Remove old "loading" ico and "load more" add "loading" ico
    t.prepend('<div id="mr_loading" class="progbar_center">&nbsp;</div>');
    // Find first date divider
    let first_datediv = t.find('.pm_date_div:first');
    // Find first msg
    let first_msg = t.find('.pm_msg:first');

    // Set ajax request marker
    window.pm_ajax_request_active = 1;
    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'load_messages',
            'thread': thread_id,
            // Get first msg_id from element id attribute (Oldest message)
            'last_msg_id': t.find('.pm_msg:first').attr('id').replace('msg_', '')
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.body) {
            t.prepend(data.body);
            // New msgs can arrive
            window.messages_highlighted = 1;
            // Scroll to date divider, remove dup date dividers
            window.setTimeout(function () {
                // Do below only if messages loaded
                if (t.find('.no_messages').length) return;
                // Chk for same date divider in loaded messages
                var date = first_datediv.data('date');
                if (t.find('div[data-date=' + date + ']').length > 1) {
                    // Remove duplicate old
                    first_datediv.remove();
                }
                // Remove "was last online"
                var lo = t.find('.msg_last_online');
                if (lo.length > 1) {
                    lo[0].remove();
                }
                // Scroll to latest read msg
                window.setTimeout(function () {
                    if (typeof t.scrollTo === 'function') {
                        t.scrollTo(first_msg);
                    }
                }, 100);
            }, 200);
        } else {
            if (data.error) {
                t.prepend(err_div.html(data.error));
            } else {
                t.prepend(err_div.html(err_txt));
            }
        }
    }).fail(function (data, textStatus, jqXHR) {
        t.prepend(err_div.html(err_txt));
    }).always(function () {
        // Unset ajax request marker
        window.pm_ajax_request_active = 0;
        window.load_more_msg_active = 0;
        $('#mr_loading').remove();
    });
    return false;
}

window.threads_main_resize = function () {
    // window hight - header
    let wh = $(window).height() - 207;
    // change also in pm.css:128 #threads_main.full, #threads_main.full #threads_left
    wh = wh > 396 ? wh : 396;
    let max_photo_width = ($('#threads_right').width()) - 96;
    let style = $("<style type='text/css'>" +
        " #threads_main, #threads_left {max-height: " + wh + "px;}" +
        " #threads_main.full, #threads_main.full #threads_left" + "{height: " + wh + "px;}" +
        " #threads_main.full #threads_right {height: " + (wh - 46) + "px;}" +
        " #threads_main.full.send #threads_right {height: " + (wh - 181) + "px;}" +
        " #threads_main.full.send.big_msg #threads_right {height: " + (wh - 321) + "px;}" +
        // Ensure photos not overlaps threads_right
        " .msg_txt IMG {max-width: " + max_photo_width + "px;}" +
        "</style>");

    if ($('#js_style').length) {
        $('#js_style').replaceWith(style);
    } else {
        style.appendTo('head');
    }
}

window.scroll_thread_to_middle = function (thread) {
    const tl = $('#threads_left');
    //Scroll '#threads_left' and put active thread in the middle
    if (typeof tl.scrollTo === 'function') {
        tl.scrollTo(tl.scrollTop + thread.position().top - (tl.height() / 2) + 39, 500);
    }
}

window.update_change_view_link = function (thread_id) {
    // Update "change_view" link
    var view = window.pm_view_mode === 'chat' ? 'paged' : 'chat';
    if (typeof thread_id == 'undefined') {
        $('#change_view').attr('href', '/pm.php?view=' + view);
    } else {
        $('#change_view').attr('href', '/pm.php?view=' + view + '&thread_id=' + thread_id);
    }
}

window.view_thread = function (thread_id, reload_messages) {
    window.reload_ads();

    $('#alert_bar').remove();

    var msg = $('#message'),
        tl = $("#threads_left"),
        thread = $("#thread_" + thread_id),
        tm = $("#threads_main"),
        use_browser_cached_draft = typeof last_draft[thread_id] != 'undefined';

    // If reload_messages is false, we only mark thread as active. Without reset form.

    if (reload_messages) {
        // Make 'send message' smaller and make 'message' textarea empty
        $('#threads_main').removeClass('send');
        msg.val('');
        $('#send_thread_id').val(thread_id);
        // Remove autosaved msg
        $('#markitup_msg').empty();
    }

    // Remove "new" in 3 seconds
    window.setTimeout(function () {
        window.remove_new_mark();
    }, 3000);

    // If we have saved draft on clientside - put it into "message"
    if (reload_messages && use_browser_cached_draft) {
        msg.val(last_draft[thread_id]);
        $('#threads_main').addClass('send');
    }
    // With this class '#threads_right' and 'send message' form will be visible
    tm.addClass('full');
    // Remove all old active threads
    tl.find('.pm_thread').removeClass('active');
    // Set new thread as active
    thread.addClass('active');

    // Scroll whole page to top status bar
    if ($(window).height() > 590) {
        if (typeof $.scrollTo === 'function') {
            $.scrollTo($("#page"));
        }
    }

    // Scroll '#threads_left' and put active thread in the middle
    window.scroll_thread_to_middle(thread);

    window.update_change_view_link(thread_id);

    // If reload_messages is false we only mark thread as active. Without reloading messages.
    if (!reload_messages) return false;

    // Remove new msgs counter
    thread.find('.pm_new_circle').remove();
    // Remove red background
    thread.removeClass('new');

    // Show 'hide thread' button. Remove css class which rendered on server side if any
    var ctw = $('#close_thread_wrap');
    ctw.removeClass('visible');
    ctw.show();

    var c = $('#threads_right');

    // Set ajax request marker
    window.pm_ajax_request_active = 1;
    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'load_messages',
            'thread': thread_id,
            // If no cached draft on browser,
            'load_draft': use_browser_cached_draft ? 0 : 1
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        c.empty();
        if (data.body) {
            c.append(data.body);
            // Update "last opened by contact" timestamp
            if (typeof data.lobc != 'undefined') {
                thread.data('lobc', data.lobc);
            }
            // Load draft if any
            if (data.draft.length > 0) {
                msg.val(data.draft);
                $('#threads_main').addClass('send');
            }
            window.setTimeout(function () {
                if (typeof c.scrollTo === 'function') {
                    c.scrollTo(c.get(0).scrollHeight);
                }
            }, 200);
        } else {
            if (data.error) {
                c.append(err_div.html(data.error));
            } else {
                c.append(err_div.html('This conversation is currently empty.'));
            }
        }
        window.focus_msg_box();
    }).fail(function (data, textStatus, jqXHR) {
        c.empty();
        c.append(err_div.html(err_txt));
    }).always(function () {
        // Unset ajax request marker
        window.pm_ajax_request_active = 0;
    });
    return false;
}

window.close_thread = function (no_history) {
    // If html5 history not supported use redirect
    if (!htm5_history_supported) {
        window.location.href = window.location.protocol + '//' +
            window.location.hostname + window.location.pathname;
        return false;
    }

    window.update_change_view_link();

    let tm = $("#threads_main");
    $('#close_thread_wrap').hide();
    tm.find('.pm_thread').removeClass('active');
    tm.removeClass('full');

    if (typeof no_history != 'undefined' && no_history) {
        return false;
    }

    // Push url to html5 history
    let stateObj = { 'close_thread': true };
    history.pushState(stateObj, null, '/pm.php');
}

window.reload_ads = function () {
    return; // Don't reload ads here for now (globally applied)
}

window.find_contact = function () {
    window.reload_ads();
    let t = $('#username_filter'),
        username = $.trim(t.val());

    if (username.length < 3 || t.attr('title') === username) {
        // Avoid double enter press after alert
        t.blur();
        alert('Username must be at least three characters.');
        return false;
    }

    window.location.href = window.location.protocol + '//' +
        window.location.hostname + window.location.pathname + '?filter=username&filter_value=' + escape(username);

    return false;
}

window.load_older_conversations = function () {
    load_more_threads_active = 1;
    window.reload_ads();
    let c = $('#threads_left'),
        a = $('#load_more_conversations'),
        fl = $('#filters');
    a.empty().addClass('progbar_center');

    // Set ajax request marker
    window.pm_ajax_request_active = 1;

    $.ajax({
        type: "POST",
        url: "/pm.php",
        data: {
            'action': 'more_threads',
            'from': c.find('.pm_thread').length,
            'filter': fl.data('filter'),
            'max_msg_id': c.data('max-msg-id')
        },
        dataType: 'json'
    }).done(function (data, textStatus, jqXHR) {
        if (data.body) {
            let at_id = window.get_active_thread_id();
            if (data.new_data) {
                c.empty();
            }
            c.append(data.body);
            // Update max-msg-id to track changes of threads list
            c.data('max-msg-id', data.max_msg_id);
            if (data.new_data && at_id && $('#thread_' + at_id).length) {
                // If threads was reloaded set active thread again,
                // without reloading messages
                window.view_thread(at_id, false);
            }
        } else {
            c.append(err_div);
        }
    }).fail(function (data, textStatus, jqXHR) {
        c.append(err_div);
    }).always(function () {
        // Unset ajax request marker
        window.pm_ajax_request_active = 0;
        window.load_more_threads_active = 0;
        a.remove();
    });
    return false;
}
