import crosstab from 'crosstab';

window["prepareNotification"] = function (entity) {
    // skip THREAD_SYNC_MESSAGE and FEED_SYNC_MESSAGE types
    if (entity.type === 'THREAD_SYNC_MESSAGE' || entity.type === 'FEED_SYNC_MESSAGE') {
        return null;
    }

    let data = entity.data,
        sender = entity.sender,
        cdn = '//ipstatic.net/';

    // default value When Sender is null...
    let thumb = cdn + 'images/male-empty-profile.png';

    let notification = {}

    if (sender) {
        if (sender.avatar) {
            thumb = sender.avatar.thumb_small;
        } else if (sender.sex) {
            thumb = cdn + 'images/' + sender.sex + '-empty-profile.png';
        }

        notification = {
            url: '/' + sender.username,
            username: sender.username,
            img_url: thumb,
            img_dims: "width='50' height='50'",
        };
    }

    /**
     * We don't use some notifications because it's rly hard to support in legacy:
     *
     * FEED_SYNC_MESSAGE
     * THREAD_SYNC_MESSAGE
     */
    switch (entity.type) {
        case 'COMMENT_MESSAGE':
            notification.title = 'New comment on your feed';
            notification.text = data.message;

            return notification;
        case 'COMMENT_REPLY_MESSAGE':
            notification.title = 'New reply to your comment';
            notification.text = data.message;

            return notification;
        case 'FRIEND_ACCEPT_MESSAGE':
            notification.title = sender.username + ' accepted your friendship!';
            notification.text = "Say 'Hi'!";

            return notification;
        case 'BIRTHDAY_MESSAGE':
            notification.title = sender.username + '`s birthday today';
            notification.text = "Congratulate your friend on birthday!";

            return notification;
        case 'FRIEND_JOIN_MESSAGE':
            notification.title = sender.username + ' just joined';
            notification.text = "Say 'Hi'!";

            return notification;
        case 'FRIEND_REQUEST_MESSAGE':
            notification.title = sender.username + ' requested your friendship';
            notification.text = "Say 'Hi'!";

            return notification;
        case 'LOGIN_NOTIFICATION':
            notification.title = sender.username + ' is online';
            notification.text = "Say 'Hi'!";

            return notification;
        case 'LIKE_MESSAGE':
            // @todo
            break;
        case 'PHOTO_COMMENT':
            notification.url = '/app/photo?pid=' + data.target_id;
            notification.title = 'New photo comment from ' + sender.username;
            notification.text = data.snippet ? data.snippet : data.message;
            notification.text = escapeHtml(notification.text);

            return notification;
        case 'THREAD_NEW_MESSAGE':
            notification.url = '/pm.php?thread_id=' + data.thread_id;
            notification.title = 'New message from ' + sender.username;
            notification.text = data.snippet ? data.snippet : data.message;
            notification.text = escapeHtml(notification.text);

            return notification;
        case 'THREAD_VIEWED':
            // @todo: handle in pm
            break;

        case 'WALL_MESSAGE':
            notification.url = '/app/wall';
            notification.title = 'New post on your wall';
            notification.img_url = data.thumb;
            notification.text = data.snippet ? data.snippet : data.message;
            notification.text = escapeHtml(notification.text);

            return notification;
    }

    return null;
}

window["escapeHtml"] = function (text) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function (m) {
        return map[m];
    });
}

window["onMessage"] = function (wsMessage) {
    let data;
    try {
        data = JSON.parse(wsMessage.data);
    } catch (e) {
        console.log(wsMessage);
    }

    if (data === undefined) {
        console.log('unexpected undefined event data', wsMessage);
        return;
    }

    if (data.type === 'COUNTER_UPDATE') {
        switch (data.id) {
            case 'wpNewCnt':
            case 'frNewCnt':
            case 'pmNewCnt':
            case 'ncNewCnt':
            case 'phNewCnt':
                $('#' + data.id).text('(+' + data.value + ')');
                break;
        }
    } else {
        const notification = prepareNotification(data);

        if (notification) {
            window.showNotifications([notification])
        }
    }
}

$(document).ready(function () {
    var retryCount = 0;

    var connect = function () {
        if (!document.getElementsByClassName('notYou').length) {
            return false;
        }

        var wspath = 'wss://api.interpals.net/v1/ws';

        if (document.location.hostname === 'local.interpals.net') {
            wspath = 'ws://api.local.interpals.net/v1/ws';
        }

        var ws = new WebSocket(wspath);

        ws.onopen = function () {
            retryCount = 0;
        };

        if (crosstab.supported) {
            ws.onmessage = function (event) {
                crosstab.broadcast('ws:message', event)
            };
        } else {
            ws.onmessage = onMessage;
        }

        ws.onclose = function (event) {
            var retryTimeout = Math.pow(2, Math.min(retryCount, 8));
            retryCount++;

            setTimeout(
                function () {
                    connect()
                },
                retryTimeout * 1000
            );
        };
    };

    if (crosstab.supported) {
        crosstab.on('ws:message', function (event) {
            onMessage(event.data);
        });

        const isMaster = crosstab.util.tabs.MASTER_TAB.id === crosstab.id;
        if (isMaster) {
            console.log("master - connecting");
            connect();
        }

        crosstab.on(crosstab.util.eventTypes.becomeMaster, function (message) {
            console.log("elected new master - connecting");
            connect();
        });
    } else {
        connect()
    }
});
