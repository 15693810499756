$(function() {
    const gcMShow = function (event) {
        $("#gcManual").toggleClass("display_none");
        $("#gcAuto").toggleClass("display_none");
        event.preventDefault();
    }

    $("#gcManualLink").click(gcMShow);
    $("#gcManualLink2").click(gcMShow);
});
