/* All below is for mprofile.php */
$(function() {
    // Remove language drop-down element
    $('#langs_know_dds, #langs_learn_dds').on('click', '.delLangSelect', function () {
        $(this).parents('.mpLangDropDown').remove();
        $(this).parents('.mpLangLearnDropDown').remove();
    });

    // Add new language drop-down element by cloning existing one
    $('.addLangSelect').click(function () {
        $(this).parent().find('.langdds').append('<div class="mpLangDropDown">' +
            $('#' + $(this).attr('sel-prefix') + '_knowdata').html() +
            '</div>');
    });

    $('.addLangLearnSelect').click(function () {
        $(this).parent().find('.langdds').append('<div class="mpLangLearnDropDown">' +
            $('#' + $(this).attr('sel-prefix') + '_learndata').html() +
            '</div>');
    });

    $('#profForm').submit(function () {
        // Remove default language data (that was used for cloning)
        $(".langdata").remove();

        // Check if one of limited fields changed and show warning
        var $username = $('#username'),
            $day = $('#day'),
            $month = $('#month'),
            $year = $('#year'),
            $sex = $('#sex'),
            $country = $('#country'),
            $region = $('#region'),
            $city = $('#city'),
            warning = '';

        if ($username.length && $username.val() != $username.data('original-value')) {
            warning += 'Username';
        }
        if ($day.length &&
            ($day.val() != $day.data('original-value') ||
            $month.val() != $month.data('original-value') ||
            $year.val() != $year.data('original-value'))) {
            warning += (warning.length ? ', ' : '') + 'Birth';
        }
        if ($sex.length && $sex.val() != $sex.data('original-value')) {
            warning += (warning.length ? ', ' : '') + 'Sex';
        }
        if ($country.length &&
            ($country.val() != $('#original_country').data('original-value') ||
            $region.val() != $('#original_region').data('original-value') ||
            $city.val() != $('#original_city').data('original-value'))) {
            warning += (warning.length ? ', ' : '') + 'Location';
        }

        if (warning.length) {
            var msg = 'You changed the following fields with change limitations: ' +
                warning + ". \n\nThis means that you may not be able to change these fields again. " +
                "Please make sure that the information is correct. \n\n" +
                'Are you sure you want to continue ?';
            return confirm(msg);
        }
        return true;
    });

    // Show password as required when trying to change email
    var password = $('[key="email-password"]'),
        row = password.parents('.mprofRow'),
        email = password.parents('.mpTable').find('#email');
    email.on('input', function () {
        if (email.attr('placeholder') != email.val()) {
            if (row.is(':hidden')) {
                row.css('background-color', '#ffdddd').show('fast', function () {
//          $(this).animate({
//              'background-color': '#ffffff'
//          }, 300);
                })
            }
        } else {
            row.hide('fast')
        }
    })
});
