window["a"] = 1;

window["apiHost"] = document.location.hostname === 'local.interpals.net'
    ? 'api.local.interpals.net'
    : 'api.interpals.net';

window["fb_logout"] = function () {
    // Backup variant if fb will fail to call runLogoutProccess
    window.setTimeout(function () {
        window.location = "/app/auth/logout";
    }, 2500);
    FB.getLoginStatus(runLogoutProccess, true);
    return false;
}

window["ge"] = function () {
    var ea;
    for (var i = 0; i < arguments.length; i++) {
        var e = arguments[i];
        if (typeof e == 'string')
            e = document.getElementById(e);
        if (arguments.length == 1)
            return e;
        if (!ea)
            ea = [];
        ea[ea.length] = e;
    }
    return ea;
}

window["is_mobile_browser"] = function () {
    var a = navigator.userAgent || navigator.vendor || window.opera;
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4));
}

window["show"] = function () {
    for (var i = 0; i < arguments.length; i++) {
        var element = ge(arguments[i]);
        if (element && element.style) element.style.display = '';
    }
    return false;
}

window["hide"] = function () {
    for (var i = 0; i < arguments.length; i++) {
        var element = ge(arguments[i]);
        if (element && element.style) element.style.display = 'none';
    }
    return false;
}

window["toggle"] = function () {
    for (var i = 0; i < arguments.length; i++) {
        var element = ge(arguments[i]);
        element.style.display = (element.style.display == 'block') ? 'none' : 'block';
    }
    return false;
}

window["showhide"] = function (ele) {
    if (ge(ele).style.display == "none") {
        show(ele);
    } else {
        hide(ele);
    }
}
// called in template.php
window["expand"] = function (item, layer, expanded_class, contracted_class) {
    ge(item).className = (ge(item).className == expanded_class) ? contracted_class : expanded_class;
    showhide(layer);
}

// mark all checkboxes checked (mbox.php, etc.)
window["check_all"] = function (fname, cboxname) {
    let f = ge(fname);
    for (var i = 0; i < f.elements.length; i++) {
        var e = f.elements[i];
        if ((e.name == cboxname) && (e.type === 'checkbox')) {
            e.checked = f.allbox.checked;
        }
    }
}

// For forms that should only be submitted once
window["sfoSubmitted"] = false;
window["submitFormOnce"] = function (whichForm, tmpMsg) {
    if (window["sfoSubmitted"] == true) {
        return;
    }
    whichForm.submit();
    whichForm.submitButton.value = tmpMsg;
    whichForm.submitButton.disabled = true;
    window["sfoSubmitted"] = true;
}

window["disable"] = function (element) {
    element.selectedIndex = 0;
    element.disabled = true;
    return true;
}

window["invisible"] = function (id) {
    document.getElementById(id).style.visibility = "hidden";
    return false;
}

//Sets cookie_name for current domain, if expire_in_seconds == null cookie will
// be deleted
window["setCookie"] = function (cookie_name, value, expire_in_seconds) {
    var expire_date = new Date();
    expire_date.setSeconds(expire_in_seconds);
    var prepared_value = escape(value)
        + ((expire_in_seconds == null) ? "" : "; expires="
            + expire_date.toUTCString());
    document.cookie = cookie_name + "=" + prepared_value;
    return true;
}

window["no_warnings"] = function () {
    $.ajax({
        url: window.location.href.replace(window.location.search, '?todo=no_warnings')
    }).done(function () {
        $('#scamwarn').remove();
    });
}

/*
 Following functions are moved from mprofile.php and display/signup.php
 Each window["is"] = function duplicated in the original files
 */

// Used only in includes/class_geo.php
window["geo_data"] = function (todo, geoForm) {
    geoForm.todo.value = "geo";
    geoForm.geo_todo.value = todo;
    geoForm.action = location.pathname;
    geoForm.submit();
}

// Not used anywhere, delete it?
window["getSel"] = function (ob) {
    var ar = [];
    while (ob.selectedIndex != -1) {
        ar.push(ob.selectedIndex);
        ob.options[ob.selectedIndex].selected = false;
    }
    for (let i in ar) {
        ob.options[ar[i]].selected = true;
    }
    return ar;
}

window["testfunc"] = function () {
    alert('test');
};

// Not used anywhere, delete it?
window["clearList"] = function (list) {
    for (let x = list.length; x >= 0; x = x - 1) {
        list[x] = null;
    }
}
// Temporary cache buster (nginx/cf)
window["cB"] = 20200708131535;

