// Bind only on photo.php
window["photoLoadStart"] = function () {
    // Expand textarea when focused
    $('#cmtFormTA').focus(function () {
        // For some reason, can't just override height in a existing class
        $(this).removeClass('cfTA-default');
        $(this).addClass('cfTA-onfocus');
        $('#cmtFormSubmit').show();
    });

    // Init photo info cache
    window.photo_comments_cache = [];
    window.photo_info_cache = [];

    // Preload next few images
    ploadNxt($('#thNextLnk').attr('rel'), 4);

    if (!htm5_history_supported) {
        return;
    }

    /* ----------------- Below photo buttons related code ---------------- */

    // Listen to htm5 history events
    window.addEventListener('popstate', function (e) {
        // Jump to photo in path (simulate browser history)
        if (e.state &&
            typeof e.state.pid != 'undefined' && !isNaN(e.state.pid) &&
            (e.state.pid != '')) {
            chPh(e.state.pid);
        }
    }, false);

    function updNxtPrev(obj, d, nxt) {
        if (!d || !d.url) {
            return;
        }
        obj.attr('src', d.url);
        obj.attr('width', d.w);
        obj.attr('height', d.h);
        obj.parents('a').attr('rel', nxt);
    }

    // Get photo data either from cache (#phPreload) or backend
    function getPhDat(pid, f) {
        if (!pid || pid == '0') {
            return false;
        }
        if (typeof window.photo_info_cache[pid] == 'undefined') {
            // Get data from server if we haven't cached it yet
            $.getJSON("/app/helpers/photo?pid=" + pid + "&t=p", function (d) {
                window.photo_info_cache[pid] = d;
                // Pre-download image
                (new Image()).src = d.url;
                f(d, pid);
            });
        } else
            f(window.photo_info_cache[pid], pid);
    }

    // Update current photo + thumbs using pid & data (d)
    function updPh(pid, d) {
        pid = String(pid); // or else jquery.address breaks
        $('#phCmtRepLinks').addClass("sqr_progbar");
        // Short var names for ids
        let a = $('#bigPhotoLnk'),
            n = $('#phNextImg'),
            p = $('#phPrevImg');

        // Set big photo
        a.css('background-image', 'url(' + d.url + ')');
        a.css('width', d.w + 'px');
        a.css('height', d.h + 'px');
        a.attr('rel', d.n);
        a.attr('pid', pid);
        // Set thumbs
        updNxtPrev(n, d.n_th, d.n);
        updNxtPrev(p, d.p_th, d.p);
        // Load comments
        load_photo_comments(pid, 0);
        // Update comment post vars
        $('#cmtFormpid').val(pid);

        $('#phIdx').html(d.idx);
        $('#phCmtRepLinks').removeClass('sqr_progbar');
        if (d.d && d.d != 'null') {
            $('#phDesc').text(d.d);
        }
        $('#phAdd').html(d.a);
        $('#tineyeSrchLnk').attr('href',
            "http://www.tineye.com/search?url=" + d.url);
        $('#grsSrchLnk').attr('href',
            "https://lens.google.com/uploadbyurl?url=" + d.url);

        // Change photo id in mod form
        var mpid = $('#mod_photo_id');
        if (mpid.length) {
            mpid.val(pid);
        }
    }

    // Cache image data for next few images
    function ploadNxt(pid, max) {
        if (max > 0) {
            getPhDat(pid, function (d, pid) {
                ploadNxt(d.n, max - 1);
            });
        }
    }

    // Change current image + preload following 5
    var photosViewed = 0;

    function chPh(pid) {
        if (!pid || pid == '0') {
            return false;
        }
        photosViewed++;
        var callback = function (d, pid) {
            updPh(pid, d);
            ploadNxt(d.n, 5);
            $('.pr_' + d.pid).remove();
        };
        $("#cmt-pid").val(pid)
        $("#cmtForm").attr("action", "/app/photo?pid=" + pid + "&master=1#comments")
        getPhDat(pid, callback);
    }

    function clkPh(e) {
        var pid = $(this).attr('rel');
        if (!pid || pid == '0') {
            return false;
        }
        chPh(pid);
        e.preventDefault();
        pushToHistory(pid);
    }

    function pushToHistory(pid) {
        // Push url to html5 history
        var stateObj = {
            'pid': pid
        };
        if (!pid || pid == '0') {
            return false;
        }
        history.pushState(stateObj, null, '/app/photo?pid=' + pid);
    }

    $('#bigPhotoLnk').click(clkPh);
    $('#thNextLnk').click(clkPh);
    $('#thPrevLnk').click(clkPh);

    $(document).keydown(function (e) {
        if ($('#cmtFormTA').is(":focus"))
            return;
        var code = e.keyCode || e.which,
            pid = null;
        if (code == 39) {
            e.preventDefault();
            pid = $('#thNextLnk').attr('rel');
            if (pid) {
                chPh(pid);
                pushToHistory(pid);
            }
        }
        if (code == 37) {
            e.preventDefault();
            pid = $('#thPrevLnk').attr('rel');
            if (pid) {
                chPh(pid);
                pushToHistory(pid);
            }
        }
    });

    // Make sure right/left arrows work as expected in comment box
    $('.cmtFormBox').keydown(function (e) {
        e.stopPropagation();
    });
};

window["load_photo_comments"] = function (pid, offset) {
    offset = typeof offset == 'undefined' ? 0 : offset;

    var c = $('.cmtBox'), key = pid + ":" + offset, lpc = c
            .find('.load_photo_comments'),
        // If offset > 0 load more else load new
        a = offset > 0 ? lpc : c;

    a.html('').addClass('sqr_progbar');

    var err_div = '<div style="color:red">Error during processing, please reload page and try again.</div>';

    if (typeof window.photo_comments_cache[key] == 'undefined') {
        // Load and cache
        var postData = { csrf_token: $('meta[name="csrf_token"]').attr('content') };
        $.ajax({
            type: "POST",
            url: "/app/helpers/photo",
            data: {
                'pid': pid,
                'offset': offset,
                't': 'c',
                'csrf_token': postData.csrf_token
            },
            dataType: 'html'
        }).done(function (data, textStatus, jqXHR) {
            var c = $('.cmtBox');
            if (data) {
                c.append(data);
                // Cache
                window.photo_comments_cache[key] = data;
            } else {
                c.append(err_div);
            }
        }).fail(function (data, textStatus, jqXHR) {
            $('.cmtBox').append(err_div);
        }).always(function () {
            if (offset > 0) {
                // If load more remove load_photo_comments
                lpc.remove();
            } else {
                // Remove "loading class" from ".cmtBox"
                $('.cmtBox').removeClass("sqr_progbar");
            }
        });
    } else {
        // Load from cache
        if (offset > 0) {
            // If load more remove load_photo_comments
            lpc.remove();
        } else {
            // Remove "loading class" from ".cmtBox"
            $('.cmtBox').removeClass("sqr_progbar");
        }
        c.append(window.photo_comments_cache[key]);
    }
    return false;
};

window["photoMatch"] = window.location.href.match(/[\?#]|photo\.php|\/app\/photo/);
if (photoMatch && (photoMatch[0] === 'photo.php' || photoMatch[0] === '/app/photo')) {
    $(photoLoadStart);
}

window["photoMatch"] = undefined;

